import React from 'react';
import Card from 'react-bootstrap/Card';

import './ArticleCard.css';

class ArticleCard extends React.Component {
  render() {
    let { image, title, author, date, heightImage, paddingCard, widthCard, isShadow } = this.props;

    let imageStyle = {}
    if (heightImage !== undefined) {
      imageStyle = {
        "height": heightImage
      }
    }


    let articleCardStyle = {}
    if (paddingCard !== undefined) {
      articleCardStyle = {
        "padding": paddingCard,

      }
    }

    let cardStyle = {}
    if (widthCard !== undefined) {
      if (isShadow) {
        cardStyle = {
          "width": widthCard,
          "borderRadius": "12.1px",
          "boxShadow": "0 2px 21px 0 rgba(0, 0, 0, 0.08)",
          "backgroundColor": "#ffffff",
        }
      } else {
        cardStyle = {
          "width": widthCard,
        }
      }

    }

    let authorHtml = <p className="author-card">{author}</p>
    let dateHtml = <p className="date-card">{date}</p>
    if (author == null || author === "") {
      authorHtml = null
    }
    if (date == null) {
      dateHtml = null
    }

    return (
      <div className="article-card" style={articleCardStyle}>
        <Card style={cardStyle}>
          <Card.Img variant="top" src={image} style={imageStyle} />
          <Card.Body>
            <p className="title-card">{title}</p>
            {authorHtml}
            {dateHtml}
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default ArticleCard;
