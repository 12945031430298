import React from 'react';
import _ from 'lodash';
import moment from 'moment'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Pagination from "react-js-pagination";

import Navigation from '../../components/Navigation';
import Footer from '../../components/Footer';
import ArticleCard from '../../components/ArticleCard';
import ListCard from '../../components/ListCard';
import './NewsList.css';

import { NewsApi } from '../../api'

var idLocale = require('moment/locale/id');
moment.locale('id', idLocale);
class NewsList extends React.Component {
	state = {
		activePage: 1,
		highlightsItems: [],
		moreItems: [],
		count: 0,
	}

	componentDidMount() {
		const { activePage } = this.state
		this.fetcHighlightNews()
		this.fetchNews(activePage)
	}

	fetcHighlightNews = () => {
		return new Promise((resolve, reject) => {
			NewsApi.get({ limit: 3, page: 1 })
				.then(async ({ data }) => {
					let highlightsItems = data.data
					await this.setState({ highlightsItems }, () => {
						resolve()
					})
				})
				.catch(err => {
					alert('Terjadi kesalahan saat mengambil data highlights')
					reject()
				})

		})
	}

	fetchNews = (page) => {
		return new Promise((resolve, reject) => {
			NewsApi.get({ limit: 5, page: page })
				.then(async ({ data }) => {
					let moreItems = data.data
					let count = data.count
					await this.setState({ moreItems, count }, () => {
						resolve()
					})
				})
				.catch(err => {
					alert('Terjadi kesalahan saat mengambil data news')
					reject()
				})

		})
	}

	handlePageChange(pageNumber) {
		this.fetchNews(pageNumber)
		this.setState({ activePage: pageNumber });
	}

	handleNewsDetail(id) {
		window.open('/news/detail/' + id, '_self')
	}

	render() {
		let i = 0
		const { highlightsItems, moreItems, count } = this.state

		return (
			<div className="news-list">
				<Navigation />
				<Container className="content">
					<Row>
						<Col sm={6}>
							<h1 className="title">News</h1>
						</Col>
					</Row>
					<Row>
						<Col sm={6}>
							<h4 className="highlights">Highlights</h4>
						</Col>
					</Row>
					<Row>
						{_.map(highlightsItems, item => {
							return (
								<div onClick={() => this.handleNewsDetail(item.id)} className="mouse-over">
									<ArticleCard
										image={item.image}
										title={item.name}
										date={moment(item.createdAt).format('DD MMMM YYYY')}
										heightImage="138px"
										paddingCard="0 10px"
										widthCard="300px"
										isShadow={true}
									/>
								</div>
							)
						})}
					</Row>
					<Row>
						<Col sm={4}>
							<h4 className="more-from-us">More From Us</h4>
						</Col>
					</Row>
					{
						_.map(moreItems, item => {
							i++
							return (
								<div onClick={() => this.handleNewsDetail(item.id)} className="mouse-over">
									<ListCard
										data={item.image}
										title={item.name}
										date={moment(item.createdAt).format('DD MMMM YYYY')}
										author={item.publishedBy}
										category={item.categoryNews.name}
										type="image"
									/>
									{i < 5 ? <hr style={{ marginBottom: "40px" }} /> : null}
								</div>
							)
						})
					}
					<Row>
						<Col>
							<Pagination
								activePage={this.state.activePage}
								itemsCountPerPage={5}
								totalItemsCount={count}
								pageRangeDisplayed={5}
								onChange={this.handlePageChange.bind(this)}
								itemClass="page-item"
								linkClass="page-link"
							/>
						</Col>
					</Row>
				</Container>
				<Footer />
			</div>
		);
	}
}

export default NewsList;
