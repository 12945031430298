import React from 'react';

import './Description.css';

class Description extends React.Component {
  render() {
    return (
      <div className="description-header">
        <h1 style={{textAlign: "center"}}>Borang Seni Penyusunan Pokok Pikiran Kebudayaan Daerah 2019</h1>
        <p>Borang ini merupakan versi cetak dari borang Penyusunan Pokok Pikiran Kebudayaan Daerah (PPKD) 2019 Objek Seni yang terdapat dalam sistem aplikasi PPKD.</p>
        <p>Catatan Pengisian (untuk diperhatikan)</p>
        <ol>
          <li><p>Satu kuesioner ini <strong>hanya untuk 1 (satu) Cabang Seni</strong></p></li>
          <li><p>Untuk setiap Kabupaten/Kota, diharapkan untuk memperbanyak kuesioner ini <strong>sebanyak 7 (tujuh) rangkap</strong> untuk masing-masing cabang seni berikut : <strong>Seni Teater, Seni Sastra, Seni Musik, Seni Film, Seni Rupa, Seni Tari, dan Seni Media.</strong></p></li>
          <li><p>Untuk memudahkan pengisian, diharapkan untuk dapat membaca definisi operasional yang terlampir di <strong>Petunjuk Teknis Penyusunan PPKD.</strong></p></li>
        </ol>
      </div>
    );
  }
}

export default Description;
