/* eslint-disable */
/* eslint-enable */

import axios from 'axios'

export default ({ url, config }) => {
  return {
    login: data => axios.post(`${url}v1/login`, data, config()),
    logout: () => axios.get(`${url}v1/logout`, config()),
  }
}
