import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ResponsiveEmbed from 'react-bootstrap/ResponsiveEmbed';
import pathImage from '../../assets/images/path.png'


import './ListCard.css';

class ListCard extends React.Component {

    render() {
        const { category, title, author, date, data, type, view } = this.props

        let htmlData = <div className="listImage">
            <img src={data} style={{ width: "100%", height: "100%", borderRadius: "9px" }}></img>
        </div>
        if (type == 'video') {
            htmlData =
                <div className="listImage">
                    <video height="186px" width="222px" controls >
                        <source src={data} type="video/mp4" style={{ width: "222px", height: "186px" }} />
                    </video>
                </div>
        }
        let htmlAuthor = <p className="listAuthor"><span className="listDate">{author} .</span> {date}</p>
        if (author === "" || author === undefined) {
            htmlAuthor = <p className="listView"><span className="date">{date}</span></p>
        }
        if (type == 'video') {
            // htmlAuthor = <p className="listView">{view} views<span className="date"> . {date}</span></p>
            htmlAuthor = <p className="listView"><span className="date">{date}</span></p>
        }
        return (
            < div className="list-card" >
                <Row>
                    <Col sm={3}>
                        {htmlData}
                    </Col>
                    <Col sm={9}>
                        <Row>
                            <Col sm={7}>
                                <p className="listCategory">#{category.toUpperCase()}</p>
                                <h2 className="listTitle">{title}</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                {htmlAuthor}
                            </Col>
                            <Col sm={6}>
                                <p className="listShare">Bagikan <img src={pathImage}></img></p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div >
        )

    }
}

export default ListCard;
