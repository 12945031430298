/* eslint-disable */
/* eslint-enable */

import axios from 'axios'

export default ({ url, config }) => {
    const endpoint = 'v1/borangs'
    return {
        get: params => axios.get(`${url}${endpoint}`, { ...config({ type: 'dashboard' }), params: { ...params } }),
        detail: id => axios.get(`${url}${endpoint}/${id}`, config({ type: 'dashboard' })),
        create: data => axios.post(`${url}${endpoint}`, data, config({ type: 'dashboard' })),
        update: (id, data) => axios.put(`${url}${endpoint}/${id}`, data, config({ type: 'dashboard' })),
        delete: id => axios.delete(`${url}${endpoint}/${id}`, { ...config({ type: 'dashboard' }) }),
    }
}