import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './Footer.css';

class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <Container>
          <Row>
            <Col sm="6" className="information">
              <h1>Dinas Kebudayaan Pemerintah Kota Makassar</h1>
              <p>Dinas Kebudayaan Kota Makassar merupakan salah satu Perangkat Daerah yang menyelenggarakan urusan pemerintahan dalam bidang kebudayaan. Kedudukan dinas tersebut merupakan unsur pelaksana urusan pemerintahan yang menyelenggarakan bidang kebudayaan yang diatur dalam Peraturan Daerah Kota Makassar Nomor 08 Tahun 2016 tentang Pembentukan dan Susunan Perangkat Daerah Kota Makassar.</p>
            </Col>
            <Col sm="6">
              <div className="suggest-box">
                <div className="block-suggest">
                  <p><strong>Punya saran?</strong></p>
                  <p>Hubungi kami kapan pun</p>
                </div>
                <div className="block-suggest">
                  <Row>
                    <Col sm="6">
                      <p className="label">Email</p>
                      <p>dkbd.2017@gmail.com</p>
                    </Col>
                    <Col sm="6">
                      <p className="label">Telepon</p>
                      <p>(0411) 449817</p>
                    </Col>
                  </Row>
                </div>
                <div className="block-suggest">
                  <p className="label">Alamat</p>
                  <p>Jl. Balaikota No. 11 Kelurahan Baru</p>
                  <p>Kecamatan Ujung Pandang Kota Makassar 90714</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="navigation-footer">
            <Col sm="4">
              <h4>Profil</h4>
              <p>Tentang Kami</p>
              <p>Struktur Organisasi</p>
              <p>Visi & Misi</p>
            </Col>
            <Col sm="4">
              <h4>Informasi</h4>
              <p>Berita</p>
              <p>Inspirasimu</p>
            </Col>
            <Col sm="4">
              <h4>Destinasi Wisata</h4>
              <p>10 Objek Pemajuan Kebudayaan</p>
              <p>Cagar Budaya</p>
              <p>Koleksi Museum</p>
              <p>Sanggar Kesenian</p>
            </Col>
          </Row>
          {/* <hr className="barrier" /> */}
        </Container>
      </div>
    );
  }
}

export default Footer;
