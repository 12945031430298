import React from 'react';

import Bahasa from './Bahasa'
import Manuskrip from './Manuskrip'
import AdatIstiadat from './AdatIstiadat'
import TradisiLisan from './TradisiLisan'

import { BorangApi } from '../../api'
import Ritus from './Ritus';
import PengetahuanTradisional from './PengetahuanTradisional';
import TeknologiTradisional from './TeknologiTradisional';
import SeniUmum from './SeniUmum';
import PermainanRakyat from './PermainanRakyat';
import OlahragaTradisional from './OlahragaTradisional';
import CagarBudaya from './CagarBudaya';
import SeniTari from './SeniTari';
import SeniTeater from './SeniTeater';
import SeniSastra from './SeniSastra';
import SeniMusik from './SeniMusik';
import SeniFilm from './SeniFilm';
import SeniRupa from './SeniRupa';
import SeniMedia from './SeniMedia';


class BorangModal extends React.Component {
    state = {
        payload: {
            type: "",
            metadata: {},
        }
    }

    componentDidMount() {
        // fetch sesuai type
        this.fetchBorang(this.props.match.params.id)
    }

    fetchBorang = (id) => {
        return new Promise((resolve, reject) => {
            BorangApi.detail(id)
                .then(async ({ data }) => {
                    let payload = data
                    await this.setState({ payload }, () => {
                        resolve()
                    })
                })
                .catch(err => {
                    alert(`Terjadi kesalahan saat mengambil data borang ${err}`)
                    reject()
                })

        })
    }

    render() {
        let { type, payload } = this.state;
        let borangComponent = null
        if (payload.type === 'bahasa') {
            borangComponent = <Bahasa modalData={payload} typeModal="edit" />
        }
        if (payload.type === 'manuskrip') {
            borangComponent = <Manuskrip modalData={payload} typeModal="edit" />
        }
        if (payload.type === 'adat_istiadat') {
            borangComponent = <AdatIstiadat modalData={payload} typeModal="edit" />
        }
        if (payload.type === 'ritus') {
            borangComponent = <Ritus modalData={payload} typeModal="edit" />
        }
        if (payload.type === 'tradisi_lisan') {
            borangComponent = <TradisiLisan modalData={payload} typeModal="edit" />
        }
        if (payload.type === 'pengetahuan_tradisional') {
            borangComponent = <PengetahuanTradisional modalData={payload} typeModal="edit" />
        }
        if (payload.type === 'teknologi_tradisional') {
            borangComponent = <TeknologiTradisional modalData={payload} typeModal="edit" />
        }
        if (payload.type === 'seni_umum') {
            borangComponent = <SeniUmum modalData={payload} typeModal="edit" />
        }
        if (payload.type === 'seni_tari') {
            borangComponent = <SeniTari modalData={payload} typeModal="edit" />
        }
        if (payload.type === 'seni_teater') {
            borangComponent = <SeniTeater modalData={payload} typeModal="edit" />
        }
        if (payload.type === 'seni_sastra') {
            borangComponent = <SeniSastra modalData={payload} typeModal="edit" />
        }
        if (payload.type === 'seni_musik') {
            borangComponent = <SeniMusik modalData={payload} typeModal="edit" />
        }
        if (payload.type === 'seni_film') {
            borangComponent = <SeniFilm modalData={payload} typeModal="edit" />
        }
        if (payload.type === 'seni_rupa') {
            borangComponent = <SeniRupa modalData={payload} typeModal="edit" />
        }
        if (payload.type === 'seni_media') {
            borangComponent = <SeniMedia modalData={payload} typeModal="edit" />
        }
        if (payload.type === 'permainan_rakyat') {
            borangComponent = <PermainanRakyat modalData={payload} typeModal="edit" />
        }
        if (payload.type === 'olahraga_tradisional') {
            borangComponent = <OlahragaTradisional modalData={payload} typeModal="edit" />
        }
        if (payload.type === 'cagar_budaya') {
            borangComponent = <CagarBudaya modalData={payload} typeModal="edit" />
        }
        return (
            <div>
                {borangComponent}
            </div>
        );
    }
}

export default BorangModal;
