import React from 'react';
import _ from 'lodash';
import moment from 'moment'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import ResponsiveEmbed from 'react-bootstrap/ResponsiveEmbed';
import Pagination from "react-js-pagination";

import Navigation from '../../components/Navigation';
import Footer from '../../components/Footer';
import ListCard from '../../components/ListCard';
import './VideoList.css';
import dummyVideo from '../../assets/images/dummyVideo.mp4';
import pathPng from '../../assets/images/path.png';

import inspiration1 from '../../assets/images/inspiration/inspiration-1.png'
import inspiration2 from '../../assets/images/inspiration/inspiration-2.png'
import inspiration3 from '../../assets/images/inspiration/inspiration-3.png'
import inspiration4 from '../../assets/images/inspiration/inspiration-4.png'
import inspiration5 from '../../assets/images/inspiration/inspiration-5.png'

import { InspirationApi } from '../../api'

var idLocale = require('moment/locale/id');
moment.locale('id', idLocale);

let highlightsItemDummyImage = { name: 'Surga Wisata - Kuliner, Sejara sampai Pemandangan Apik', image: inspiration1, date: 'Apr 14, 2018' }
let highlightsItemDummyVideo = { name: 'Surga Wisata - Kuliner, Sejara sampai Pemandangan Apik', image: dummyVideo, date: 'Apr 14, 2018' }

class VideoList extends React.Component {
	state = {
		activePage: 1,
		highlightsItem: {},
		items: [],
		count: 0,
		type: "image",
		page: 1,
	}

	componentWillMount() {
		const query = new URLSearchParams(this.props.location.search);
		let type = "image"
		let page = 1
		for (let param of query.entries()) {
			switch (param[0]) {
				case 'type':
					type = param[1];
					break;
				case 'page':
					page = param[1];
					break;
				default:
					break;
			}
		}

		this.setState({ type, page })
	}

	componentDidMount() {
		const { page, type } = this.state
		this.fetchInspirations(type, page)
	}

	fetchInspirations = (type, page) => {
		return new Promise((resolve, reject) => {
			InspirationApi.get({ limit: 5, page: page, type: type })
				.then(async ({ data }) => {
					let items = data.data
					let count = data.count
					let highlightsItem = highlightsItemDummyImage
					if (type === 'video') {
						highlightsItem = highlightsItemDummyVideo
					}
					if (items.length > 0) {
						highlightsItem = items[0]
					}
					await this.setState({ items, count, highlightsItem }, () => {
						resolve()
					})
				})
				.catch(err => {
					alert(`Terjadi kesalahan saat mengambil data inspirasi ${err}`)
					reject()
				})

		})
	}

	handlePageChange(pageNumber) {
		const queryParams = [];
		const { choose } = this.state
		queryParams.push('type=' + choose);
		queryParams.push('page=' + pageNumber)
		const queryString = queryParams.join('&');
		window.open('/inspirations?' + queryString, '_self')
	}

	handleChangeHighlights(item) {
		let { highlightsItem } = this.state
		highlightsItem = item
		this.setState({ highlightsItem })
	}

	render() {
		let i = 0
		const { highlightsItem, items, count, type } = this.state

		let preview = null
		if (type === 'video') {
			preview = <iframe width="100%" type="video/webm" src={highlightsItem.image} allow="fullscreen" allowfullscreen></iframe>
			// preview = <embed type="video/webm" src={highlightsItem.image} />
		} else {
			preview = <img src={highlightsItem.image} />
		}

		return (
			<div className="video-List">
				<Navigation />
				<Container className="content">
					<Row>
						<Col sm={6}>
							<h1 className="title">{`${type === 'image' ? "Image" : "Video"}`}</h1>
						</Col>
					</Row>
					<Row>
						<Col sm={6}>
							<h4 className="highlights">Highlights</h4>
						</Col>
					</Row>
					<Row>
						<Col>
							<Row>
								<Col>
									<div style={{ width: '100%', height: 'auto' }}>
										<ResponsiveEmbed aspectRatio="21by9" style={{ borderRadius: "20px" }}>
											{preview}
										</ResponsiveEmbed>
									</div>
								</Col>
							</Row>
							<Row>
								<Col sm={6}>
									<h2 class="highlights-title">{highlightsItem.name}</h2>
								</Col>
							</Row>
							<Row>
								<Col sm={3}>
									{/* <h3 class="highlights-view">18,336 views . <span>{highlightsItem.date}</span></h3> */}
									<h3 class="highlights-view"><span>{moment(highlightsItem.createdAt).format('MMM DD, YYYY')}</span></h3>
								</Col>
							</Row>
							<Row>
								<Col sm={2}>
									<h4 class="highlights-share">Bagikan <img src={pathPng} /></h4>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col sm={4}>
							<h4 className="more-from-us">More From Us</h4>
						</Col>
					</Row>
					{
						_.map(items, item => {
							i++
							return (
								<div onClick={() => this.handleChangeHighlights(item)}>
									<ListCard
										data={item.image}
										title={item.name}
										date={moment(item.createdAt).format('MMM DD, YYYY')}
										category={"highlights"}
										type={type}
										view="18,336"
									/>
									{i < 5 ? <hr style={{ marginBottom: "40px" }} /> : null}
								</div>
							)
						})
					}
					<Row>
						<Col>
							<Pagination
								activePage={this.state.activePage}
								itemsCountPerPage={5}
								totalItemsCount={items.length}
								pageRangeDisplayed={5}
								onChange={this.handlePageChange.bind(this)}
								itemClass="page-item"
								linkClass="page-link"
							/>
						</Col>
					</Row>
				</Container>
				<Footer />
			</div>
		);
	}
}

export default VideoList;
