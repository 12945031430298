import React from 'react';
import _ from 'lodash';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import { AiFillMinusSquare, AiFillPlusSquare } from 'react-icons/ai';


class Form1 extends React.Component {
    render() {
        const { modalData, touched, handleInputChange, handleNext, handlePrev, handleInputArrayChange, handleAddArrayChange, handleDeleteArrayChange, typeModal } = this.props
        return (
            <div>
                <h1>Blok I : Identitas Umum Objek Manuskrip</h1>
                <Form.Group>
                    <Form.Label className="label">Nama Manuskrip</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Nama Manuskrip" value={modalData.manuskrip} isInvalid={touched.manuskrip && !modalData.manuskrip} onChange={e => handleInputChange(e, 'manuskrip')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Jenis manuskrip</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Jenis Manuskrip" value={modalData.jenisManuskrip} isInvalid={touched.jenisManuskrip && !modalData.jenisManuskrip} onChange={e => handleInputChange(e, 'jenisManuskrip')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <p className="label" style={{ marginBottom: "0px" }}>Lokasi Penyimpanan</p>
                <Form.Group style={{ marginTop: "-10px", marginBottom: "0px" }}>
                    <Form.Label className="label">Nama Tempat</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Nama Tempat" value={modalData.namaTempat} onChange={e => handleInputChange(e, 'namaTempat')} isInvalid={touched.namaTempat && !modalData.namaTempat} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group style={{ marginTop: "-10px" }}>
                    <Form.Label className="label">Alamat</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Alamat" value={modalData.alamat} isInvalid={touched.alamat && !modalData.alamat} onChange={e => handleInputChange(e, 'alamat')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Bahasa yang digunakan</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Bahasa yang digunakan" value={modalData.bahasaYangDigunakan} isInvalid={touched.bahasaYangDigunakan && !modalData.bahasaYangDigunakan} onChange={e => handleInputChange(e, 'bahasaYangDigunakan')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Jumlah Manuskrip</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Jumlah Manuskrip" value={modalData.jumlahManuskrip} isInvalid={touched.jumlahManuskrip && !modalData.jumlahManuskrip} onChange={e => handleInputChange(e, 'jumlahManuskrip')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Jumlah pembaca ahli manuskrip tersebut <sup>a)</sup></Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Jumlah Pembaca ahli manuskrip" value={modalData.jumlahPembaca} isInvalid={touched.jumlahPembaca && !modalData.jumlahPembaca} onChange={e => handleInputChange(e, 'jumlahPembaca')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Nama pembaca ahli jenis manuskrip tersebut<br/>Jika terdapat lebih dari satu nama, dipisahkan dengan tanda titik koma (;)</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Nama Pembaca ahli manuskrip" value={modalData.namaPembaca} isInvalid={touched.namaPembaca && !modalData.namaPembaca} onChange={e => handleInputChange(e, 'namaPembaca')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Jumlah pengakses manuskrip tersebut dalam 1 tahun terakhir <sup>a)</sup></Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Jumlah Pengakses manuskrip tersebut dalam 1 tahun terakhir" value={modalData.jumlahPengakses} isInvalid={touched.jumlahPengakses && !modalData.jumlahPengakses} onChange={e => handleInputChange(e, 'jumlahPengakses')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Button onClick={handleNext}>Next</Button>
            </div >
        )
    }
}

export default Form1;
