import React from 'react';
import _ from 'lodash';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import { AiFillMinusSquare, AiFillPlusSquare } from 'react-icons/ai';


class Form3 extends React.Component {
    render() {
        const { modalData, touched, handleInputChange, handleNext, handlePrev, handleInputArrayChange, handleAddArrayChange, handleDeleteArrayChange, typeModal } = this.props
        return (
            <div>
                <h1>Blok III : Identifikasi Produk Hukum Objek Seni Teater</h1>
                <Form.Group>
                    <Form.Label className="label">Adakah peraturan khusus di tingkat Kabupaten/Kota yang mengatur tentang seni teater yang bersangkutan?</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} value={modalData.adakahPeraturan} as="select" onChange={e => handleInputChange(e, 'adakahPeraturan')}>
                        <option value={true}>Ya</option>
                        <option value={false}>Tidak</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Jika Ya, sebutkan peraturan yang mengatur tentang hal tersebut</Form.Label>
                    <Table>
                        <thead>
                            <tr>
                                <th style={{width: "47%"}}>Nama Peraturan</th>
                                <th style={{width: "47%"}}>Bentuk Peraturan (Perda, SK, dll.)</th>
                                <th style={{width: "6%"}}><Button disabled={modalData.adakahPeraturan === 'false' || typeModal === 'edit'} onClick={(e) => handleAddArrayChange(e, 'peraturan')}><AiFillPlusSquare size={20} color="white" style={{ marginRight: "5px" }} /></Button></th>
                            </tr>
                        </thead>
                        <tbody>
                            {_.map(modalData.peraturanList, (item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <Form.Group>
                                                <Form.Control disabled={typeModal === 'edit'} type="text" placeholder="Nama Peraturan" value={item.namaPeraturan} onChange={e => handleInputArrayChange(e, 'peraturan', 'namaPeraturan', index)} isInvalid={!item.namaPeraturan}></Form.Control>
                                            </Form.Group>
                                        </td>
                                        <td>
                                            <Form.Group>
                                                <Form.Control disabled={typeModal === 'edit'} type="text" placeholder="Bentuk Peraturan" value={item.bentukPeraturan} onChange={e => handleInputArrayChange(e, 'peraturan', 'bentukPeraturan', index)} isInvalid={!item.bentukPeraturan}></Form.Control>
                                            </Form.Group>
                                        </td>
                                        <td><Button disabled={typeModal === 'edit'} onClick={(e) => handleDeleteArrayChange(e, 'peraturan', index)}><AiFillMinusSquare size={20} color="white" style={{ marginRight: "5px" }} /></Button></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Form.Group>
                <p className="description">Catatan:</p>
                <ul>
                    <li className="description">Tambahkan baris apabila terdapat lebih dari 1 peraturan</li>
                    <li className="description">Lampirkan file produk hukum tersebut dalam aplikasi</li>
                </ul>
                <Button onClick={handlePrev} style={{ marginRight: "10px", marginTop: "10px" }}>Prev</Button>
                <Button onClick={handleNext} style={{marginTop: "10px"}}>Next</Button>
            </div >
        )
    }
}

export default Form3;
