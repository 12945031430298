import React from 'react';
import _ from 'lodash';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import { AiFillMinusSquare, AiFillPlusSquare } from 'react-icons/ai';


class Form1 extends React.Component {
    render() {
        const { modalData, touched, handleInputChange, handleNext, handlePrev, handleInputArrayChange, handleAddArrayChange, handleDeleteArrayChange, typeModal } = this.props
        return (
            <div>
                <h1>Blok I : Identitas Umum Objek Adat Istiadat</h1>
                <Form.Group>
                    <Form.Label className="label">Nama Adat Istiadat</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Nama Adat Istiadat" value={modalData.adatIstiadat} isInvalid={touched.adatIstiadat && !modalData.adatIstiadat} onChange={e => handleInputChange(e, 'adatIstiadat')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Jenis Adat Istiadat</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} value={modalData.jenisAdatIstiadat} as="select" onChange={e => handleInputChange(e, 'jenisAdatIstiadat')}>
                        <option value="perkawinan">Perkawinan</option>
                        <option value="hubungan_dengan_yang_dituakan">Hubungan dengan yang dituakan</option>
                        <option value="hubungan_kekerabatan">Hubungan Kekerabatan</option>
                        <option value="hubungan_sosial_secara_umum">Hubungan sosial secara umum</option>
                        <option value="hubungan_manusia_dengan_alam">Hubungan manusia dengan alam</option>
                        <option value="kelahiran_kematian">Kelahiran/kematian</option>
                        <option value="persembahan">Persembahan</option>
                        <option value="larangan">Larangan-larangan</option>
                        <option value="hubungan_manusia_dengan_pencipta">Hubungan manusia dengan pencipta</option>
                        <option value="lainnya">Lainnya (sebutkan)</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Control disabled={typeModal === 'edit' || !(modalData.jenisAdatIstiadat === "lainnya")} required type="text" placeholder="Lainnya" value={modalData.jenisLainnya} onChange={e => handleInputChange(e, 'jenisLainnya')} isInvalid={touched.jenisLainnya && modalData.jenisAdatIstiadat === 'lainnya' && !modalData.jenisLainnya} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Etnis yang melaksanakan</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Etnis yang melaksanakan" value={modalData.etnisYangMelaksanakan} isInvalid={touched.etnisYangMelaksanakan && !modalData.etnisYangMelaksanakan} onChange={e => handleInputChange(e, 'etnisYangMelaksanakan')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Frekuensi Pelaksanaan saat ini</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} value={modalData.frekuensi} as="select" onChange={e => handleInputChange(e, 'frekuensi')}>
                        <option value="sudah_tidak_dilaksanakan">Sudah tidak dilaksanakan</option>
                        <option value="jarang">Jarang</option>
                        <option value="sering">Sering</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label" style={{ marginTop: "10px" }}>Deskripsi Adat Istiadat</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required as="textarea" placeholder="Deskripsi Adat Istiadat" row={5} value={modalData.deskripsiAdatIstiadat} isInvalid={touched.deskripsiAdatIstiadat && !modalData.deskripsiAdatIstiadat} onChange={e => handleInputChange(e, 'deskripsiAdatIstiadat')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Button onClick={handleNext}>Next</Button>
            </div >
        )
    }
}

export default Form1;
