import React from 'react';
import Card from 'react-bootstrap/Card';

import './ImageOverlays.css';

class ImageOverlays extends React.Component {
  render() {
    let { image, title, caption} = this.props;

    return (
      <div className='image-overlays'>
        <Card className="text-white">
          <Card.Img src={image} alt="Card image" className="card-image" />
          <Card.ImgOverlay>
            <div className="text">
              <Card.Title>{title}</Card.Title>
              <Card.Text>
                {caption}
              </Card.Text>
            </div>
          </Card.ImgOverlay>
        </Card>
      </div>
    );
  }
}

export default ImageOverlays;
