import React from 'react';
import _ from 'lodash';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import { AiFillMinusSquare, AiFillPlusSquare } from 'react-icons/ai';


class Form1 extends React.Component {
    render() {
        const { modalData, touched, handleInputChange, handleNext, handlePrev, handleInputArrayChange, handleAddArrayChange, handleDeleteArrayChange, typeModal, handleInputCheckboxChange, handleInputCheckboxLabelChange} = this.props
        return (
            <div>
                <h1>Blok I : Identitas Umum Objek Teknologi Tradisional</h1>
                <Form.Group>
                    <Form.Label className="label">Nama Teknologi Tradisional</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Nama Teknologi Tradisional" value={modalData.teknologiTradisional} isInvalid={touched.teknologiTradisional && !modalData.teknologiTradisional} onChange={e => handleInputChange(e, 'teknologiTradisional')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Jenis Teknologi Tradisional</Form.Label>
                    {_.map(modalData.jenisTeknologiTradisional, (item, index) => {
                        return (
                            <Form.Check
                                type="checkbox"
                            >
                                <Form.Check.Input disabled={typeModal === 'edit'} onChange={e => handleInputCheckboxChange(e, index, "jenisTeknologiTradisional")} checked={item.checked} />
                                <Form.Check.Label onClick={e => handleInputCheckboxLabelChange(e, index, "jenisTeknologiTradisional", !item.checked)}>{item.name}</Form.Check.Label>
                            </Form.Check>
                        )
                    })}
                    {/* <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Nama Tradisi Lisan (Judul Cerita)" value={modalData.tradisiLisan} isInvalid={touched.tradisiLisan && !modalData.tradisiLisan} onChange={e => handleInputChange(e, 'tradisiLisan')} /> */}
                    <Form.Control disabled={typeModal === 'edit' || !modalData.jenisTeknologiTradisional[5].checked} required type="text" placeholder="Jenis Lainnya" value={modalData.jenisLainnya} isInvalid={touched.jenisLainnya && modalData.jenisTeknologiTradisional[5].checked && !modalData.jenisLainnya} onChange={e => handleInputChange(e, 'jenisLainnya')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Periode waktu kemunculan (abad) <sup>a)</sup></Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Periode waktu kemunculan (abad)" value={modalData.perkiraanUsia} isInvalid={touched.perkiraanUsia && !modalData.perkiraanUsia} onChange={e => handleInputChange(e, 'perkiraanUsia')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Etnis yang melaksanakan</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Etnis yang melaksanakan" value={modalData.etnisYangMelaksanakan} isInvalid={touched.etnisYangMelaksanakan && !modalData.etnisYangMelaksanakan} onChange={e => handleInputChange(e, 'etnisYangMelaksanakan')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Lokasi</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Lokasi" value={modalData.lokasi} isInvalid={touched.lokasi && !modalData.lokasi} onChange={e => handleInputChange(e, 'lokasi')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Bahan baku teknologi (jika ada)</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Bahan baku teknologi" value={modalData.bahanBaku} onChange={e => handleInputChange(e, 'bahanBaku')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Fungsi Teknologi Tradisional</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Fungsi Teknologi Tradisional" value={modalData.fungsiTeknologi} isInvalid={touched.fungsiTeknologi && !modalData.fungsiTeknologi} onChange={e => handleInputChange(e, 'fungsiTeknologi')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Frekuensi Pemanfaatan Saat Ini</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} value={modalData.frekuensi} as="select" onChange={e => handleInputChange(e, 'frekuensi')}>
                        <option value="sudah_tidak_dimanfaatkan">Sudah tidak dimanfaatkan</option>
                        <option value="jarang">Jarang</option>
                        <option value="sering">Sering</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Manfaat Saat Ini</Form.Label>
                    <Form.Control disabled={typeModal === 'edit' || modalData.frekuensi === 'sudah_tidak_dimanfaatkan'} required type="text" placeholder="Manfaat Saat Ini" value={modalData.manfaatSaatIni} isInvalid={touched.manfaatSaatIni && !modalData.manfaatSaatIni} onChange={e => handleInputChange(e, 'manfaatSaatIni')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Konteks hilangnya pengetahuan</Form.Label>
                    <Form.Control disabled={typeModal === 'edit' || !(modalData.frekuensi === 'sudah_tidak_dimanfaatkan')} required type="text" placeholder="Konteks hilangnya pengetahuan" value={modalData.konteks} isInvalid={touched.konteks && !modalData.konteks} onChange={e => handleInputChange(e, 'konteks')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Manfaat pada masanya</Form.Label>
                    <Form.Control disabled={typeModal === 'edit' || !(modalData.frekuensi === 'sudah_tidak_dimanfaatkan')} required type="text" placeholder="Manfaat pada masanya" value={modalData.manfaatPadaMasanya} isInvalid={touched.manfaatPadaMasanya && !modalData.manfaatPadaMasanya} onChange={e => handleInputChange(e, 'manfaatPadaMasanya')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Potensi Manfaat di Masa Depan</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Potensi Manfaat di Masa Depan" value={modalData.potensiManfaat} isInvalid={touched.potensiManfaat && !modalData.potensiManfaat} onChange={e => handleInputChange(e, 'potensiManfaat')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Jumlah pelaku pemanfaatan <sup>a)</sup></Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Jumlah pelaku pemanfaatan" value={modalData.jumlahPelaku} isInvalid={touched.jumlahPelaku && !modalData.jumlahPelaku} onChange={e => handleInputChange(e, 'jumlahPelaku')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <p className="description">Catatan: </p>
                <ol type="a">
                    <li className="description">Dapat diisi dengan angka perkiraan (estimasi)</li>
                </ol>
                <Button onClick={handleNext}>Next</Button>
            </div >
        )
    }
}

export default Form1;
