import React from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

import './Banner.css';

class Banner extends React.Component {
  render() {
    return (
      <div className='banner'>
        <Container>
          <div className='title'>
            <h1>Makassar,</h1>
            <h1>kenali dan jelajahi.</h1>
          </div>
          <div className='caption'>
            <h3>Sekali Layar Terkembang, Pantang</h3>
            <h3>Biduk Surut ke Pantai</h3>
          </div>
          <Button className='button'>Jelajahi</Button>
        </Container>
      </div>
    );
  }
}

export default Banner;
