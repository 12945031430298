import React from 'react';

import './Description.css';

class Description extends React.Component {
  render() {
    return (
      <div className="description-header">
        <h1 style={{textAlign: "center"}}>Borang Manuskrip Penyusunan Pokok Pikiran Kebudayaan Daerah 2019</h1>
        <p>Borang ini merupakan versi cetak dari borang Penyusunan Pokok Pikiran Kebudayaan Daerah (PPKD) 2019 Objek Manuskrip yang terdapat dalam sistem aplikasi PPKD.</p>
        <p>Catatan Pengisian (untuk diperhatikan)</p>
        <ol>
          <li><p>Satu kuesioner ini <strong>hanya untuk 1 (satu) objek manuskrip</strong></p></li>
          <li><p>Jika terdapat lebih dari 1 manuskrip di Kabupaten/Kota, diharapkan untuk memperbanyak kuesioner ini sesuai dengan banyaknya manuskrip yang teridentifikasi di wilayah tersebut.</p></li>
          <li><p>Untuk memudahkan pengisian, diharapkan untuk dapat membaca <strong>definisi operasional</strong> yang terlampir di <strong>Petunjuk Teknis Penyusunan PPKD.</strong></p></li>
        </ol>
      </div>
    );
  }
}

export default Description;
