import React from 'react';
import _ from 'lodash';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ImageOverlays from 'components/ImageOverlays';
import image1 from 'assets/images/base.png';
import image2 from 'assets/images/rectangle-copy-10.png';
import image3 from 'assets/images/group-2.png';
import image4 from 'assets/images/group-3.png';
import image5 from 'assets/images/group.png';

import './Explore.css';

let imageOverlays = [
  {image: image1, size: 8, title: '10 Objek Pemajuan Wisata Kota Makassar', caption: 'Temukan 10 objek pemajuan wisata di kota Makassar'},
  {image: image2, size: 4, title: 'Cagar Budaya', caption: 'Cagar budaya sebagai jejak sejarah bangsa'},
  {image: image3, size: 4, title: 'Wisata Heritage', caption: 'Menikmati wisata heritage Makassar'},
  {image: image4, size: 4, title: 'Koleksi Museum', caption: 'Menengok koleksi museum Kota Makassar'},
  {image: image5, size: 4, title: 'Sanggar Kesenian', caption: 'Telusuri jenis-jenis kesenian di Kota Makassar'}
]

class Explore extends React.Component {
  render() {
    return(
      <div className='explore'>
        <Container>
          <h2>Jelajahi Makassar Lebih Dekat</h2>
          <p className="caption">Rekomendasi terbaik untuk perjalananmu yang lebih menyenangkan dan berkesan</p>
          <Row>
            {_.map(imageOverlays, imageOverlay => {
              return (
                <Col sm={imageOverlay.size}>
                  <ImageOverlays image={imageOverlay.image} title={imageOverlay.title} caption={imageOverlay.caption} />
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
    );
  }
}

export default Explore;
