import React from 'react';
import _ from 'lodash';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import { AiFillMinusSquare, AiFillPlusSquare } from 'react-icons/ai';


class Form1 extends React.Component {
    render() {
        const { modalData, touched, handleInputChange, handleNext, handlePrev, handleInputArrayChange, handleAddArrayChange, handleDeleteArrayChange, typeModal, handleInputCheckboxChange, handleInputCheckboxLabelChange } = this.props
        return (
            <div>
                <h1>Blok I : Identitas Umum Objek Cagar Budaya</h1>
                <Form.Group>
                    <Form.Label className="label">Mohon tulilskan seluruh Objek Cagar Budaya (CB) yang telah ditetapkan di wilayah Kabupaten/Kota yang bersangkutan beserta informasi yang dibutuhkan. <strong>Jika terdapat lebih dari 1 Objek Cagar Budaya, dapat ditambahkan baris sesuai jumlah objek Cagar Budaya yang telah ditetapkan.</strong></Form.Label>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th style={{width: "12%"}}>Nama CB</th>
                                <th style={{width: "12%"}}>Lokasi Aktual</th>
                                <th style={{width: "20%"}}>Ditetapkan oleh</th>
                                <th style={{width: "10%"}}>Tahun Penetapan</th>
                                <th style={{width: "15%"}}>Kondisi Aktual CB</th>
                                <th style={{width: "16%"}}>Deskripsi Singkat</th>
                                <th style={{width: "5%"}}><Button disabled={modalData.adakahPeraturan === 'false' || typeModal === 'edit'} onClick={(e) => handleAddArrayChange(e, 'cagarBudaya')}><AiFillPlusSquare size={20} color="white" style={{ marginRight: "5px" }} /></Button></th>
                            </tr>
                        </thead>
                        <tbody>
                            {_.map(modalData.cagarBudayaList, (item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <Form.Group>
                                                <Form.Control disabled={typeModal === 'edit'} type="text" placeholder="Nama" value={item.nama} onChange={e => handleInputArrayChange(e, 'cagarBudaya', 'nama', index)}></Form.Control>
                                            </Form.Group>
                                        </td>
                                        <td>
                                            <Form.Group>
                                                <Form.Control disabled={typeModal === 'edit'} type="text" placeholder="Lokasi" value={item.lokasi} onChange={e => handleInputArrayChange(e, 'cagarBudaya', 'lokasi', index)}></Form.Control>
                                            </Form.Group>
                                        </td>
                                        <td>
                                            <Form.Group>
                                                <Form.Control disabled={typeModal === 'edit'} value={item.ditetapkan} as="select" onChange={e => handleInputArrayChange(e, 'cagarBudaya', 'ditetapkan', index)}>
                                                    <option value="nasional">Nasional</option>
                                                    <option value="provinsi">Provinsi</option>
                                                    <option value="kab_kota">Kab/Kota</option>
                                                    <option value="belum_ditetapkan">Belum ditetapkan</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </td>
                                        <td>
                                            <Form.Group>
                                                <Form.Control disabled={typeModal === 'edit'} type="text" placeholder="Tahun" value={item.tahun} onChange={e => handleInputArrayChange(e, 'cagarBudaya', 'tahun', index)}></Form.Control>
                                            </Form.Group>
                                        </td>
                                        <td>
                                            <Form.Group>
                                                <Form.Control disabled={typeModal === 'edit'} value={item.kondisi} as="select" onChange={e => handleInputArrayChange(e, 'cagarBudaya', 'kondisi', index)}>
                                                    <option value="rusak">Rusak</option>
                                                    <option value="tidak_terawat">Tidak Terawat</option>
                                                    <option value="baik">Baik</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </td>
                                        <td>
                                            <Form.Group>
                                                <Form.Control disabled={typeModal === 'edit'} as="textarea" row={5} placeholder="Deskripsi Singkat" value={item.deskripsi} onChange={e => handleInputArrayChange(e, 'cagarBudaya', 'deskripsi', index)}></Form.Control>
                                            </Form.Group>
                                        </td>
                                        <td><Button disabled={typeModal === 'edit'} onClick={(e) => handleDeleteArrayChange(e, 'cagarBudaya', index)}><AiFillMinusSquare size={20} color="white" style={{ marginRight: "5px" }} /></Button></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Form.Group>
                <p className="description">Catatan: </p>
                <ol type="a">
                    <li className="description">Dapat diisi dengan angka perkiraan (estimasi)</li>
                </ol>
                <Button onClick={handleNext}>Next</Button>
            </div >
        )
    }
}

export default Form1;
