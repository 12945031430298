import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import inspiration1 from '../../../../assets/images/inspiration/inspiration-1.png'
import inspiration2 from '../../../../assets/images/inspiration/inspiration-2.png'
import inspiration3 from '../../../../assets/images/inspiration/inspiration-3.png'
import inspiration4 from '../../../../assets/images/inspiration/inspiration-4.png'
import inspiration5 from '../../../../assets/images/inspiration/inspiration-5.png'

import { InspirationApi } from '../../../../api'


import './Inspiration.css';

let itemsDummy = [
    { name: 'Surga Wisata - Kuliner, Sejara sampai Pemandangan Apik', image: inspiration1, date: 'Apr 14, 2018' },
    { name: 'Tips ngabuburit di tengah pandemi corona', image: inspiration2, date: 'Apr 14, 2018' },
    { name: 'Review buku Mukjizat Enzim oleh Hiromi Shinya', image: inspiration3, date: 'Apr 14, 2018' },
    { name: 'Galih dan Ratna akan melangsungkan akad nikah...', image: inspiration4, date: 'Apr 14, 2018' },
    { name: 'Buah khas kota makassar ini laris diborong turis Amerika', image: inspiration5, date: 'Apr 14, 2018' },
]

class Inspiration extends React.Component {
    state = {
        choosen: 'image',
        choosenItem: {},
        choosenItemIndex: 0,
        items: [],
    }

    componentDidMount() {
        const { choosen } = this.state
        this.fetchInspirations(choosen)
    }

    handleTypeChange = (choosen) => {
        this.setState({ choosen })
        this.fetchInspirations(choosen)
    }

    handleChoosenItem = (i) => {
        const { items } = this.state
        const choosenItem = items[i];
        console.log(choosenItem)
        const choosenItemIndex = i
        this.setState({ choosenItem, choosenItemIndex })
    }

    fetchInspirations = (type) => {
        return new Promise((resolve, reject) => {
            InspirationApi.get({ limit: 5, page: 1, type: type })
                .then(async ({ data }) => {
                    let items = data.data
                    let choosenItem = null
                    if (items.length < 1) {
                        items = itemsDummy
                    }
                    choosenItem = items[0]
                    await this.setState({ items, choosenItem }, () => {
                        resolve()
                    })
                })
                .catch(err => {
                    alert(`Terjadi kesalahan saat mengambil data inspirasi ${err}`)
                    reject()
                })

        })
    }

    render() {
        const { choosen, choosenItem, choosenItemIndex, items } = this.state
        let preview = null
        if (choosen === 'image') {
            preview = <img src={choosenItem.image} />
        } else {
            preview = <iframe type="video/webm" src={choosenItem.image} allow="fullscreen" allowfullscreen></iframe>
        }
        return (
            <div className="inspiration">
                <Container>
                    <Row className="justify-content-between inspiration-top">
                        <Col className="title-inspiration">
                            <h2>Temukan Inspirasimu</h2>
                            <h4>Jelajahi video dan foto yang bisa memberikanmu referensi untuk wisata budaya di Kota Makassar</h4>
                        </Col>
                        <Col className="all-videos">
                            <a href={choosen == 'video' ? '/inspirations?type=video' : '/inspirations?type=image'}><h4>Lihat semua {choosen === 'video' ? 'video' : 'gambar'} &gt;</h4></a>
                        </Col>
                    </Row>
                    <Row className="inspiration-choice">
                        <Col md="auto" onClick={() => this.handleTypeChange('video')}><h4 className={`${choosen == 'video' ? 'active' : ''}`}>Video</h4></Col>
                        <Col md="auto" onClick={() => this.handleTypeChange('image')}><h4 className={`${choosen == 'image' ? 'active' : ''}`}>Gambar</h4></Col>
                    </Row>
                    <Row>
                        <div style={{ width: "90.93px", marginRight: "15px" }}><div className={`${choosen == 'video' ? 'pointerActive' : 'pointer'}`}></div></div>
                        <div style={{ width: "86.67px" }}><div className={`${choosen == 'image' ? 'pointerActive' : 'pointer'}`}></div></div>
                    </Row>
                    <Row className="inspiration-content">
                        <Col sm="7" className="main-content" style={{ height: "600px" }}>
                            {preview}
                            <h4>{choosenItem.name}</h4>
                            {/* <h6>18.336 views - <strong>Apr 14, 2018</strong></h6> */}
                            <h6><strong>{moment(choosenItem.createdAt).format('MMM DD, YYYY')}</strong></h6>
                        </Col>
                        <Col sm="5" className="choice-content">
                            {
                                items.map((item, i) => {
                                    return (
                                        <div>
                                            <Row className="choice align-items-center" onClick={() => this.handleChoosenItem(i)}>
                                                <Col sm="6">
                                                    {choosen === 'image' ? <img src={item.image} /> :
                                                        <video width="100%" height="100%">
                                                            <source src={item.image} type="video/mp4" />
                                                            <source src={item.image} type="video/ogg" />
                                                                Your browser does not support the video tag.
                                                        </video>}
                                                </Col>
                                                <Col sm="6" className="text">
                                                    <h6 className={`${i === choosenItemIndex ? 'active' : ''}`}>{item.name}</h6>
                                                    <p>{moment(item.createdAt).format('MMM DD, YYYY')}</p>
                                                </Col>
                                            </Row>
                                            <hr />
                                        </div>
                                    )
                                })
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Inspiration;
