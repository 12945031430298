import React from 'react';
import _ from 'lodash';
import moment from 'moment'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import dummyImage from '../../assets/images/dummy.png';
import Navigation from '../../components/Navigation';
import Footer from '../../components/Footer';
import ArticleCard from '../../components/ArticleCard';
import './Explore.css';

import { ExploreApi } from '../../api'

var idLocale = require('moment/locale/id');
moment.locale('id', idLocale);
class Explore extends React.Component {
	state = {
		explore: {
			categoryCulture: {},
		},
		articleCards: [],
	}

	componentDidMount() {
		this.fetchExploreDetail(this.props.match.params.id)
		this.fetchExplore()
	}

	fetchExploreDetail = async exploreID => {
		ExploreApi.detail(exploreID)
			.then(async ({ data }) => {
				let explore = data
				explore.createdAt = moment(explore.createdAt).format('ddd, DD MMMM YYYY HH:mm')

				await this.setState({
					explore,
				})
			})
			.catch(async err => {
				alert(`Terjadi kesalahan saat mengambil data highlights dengan ID ${exploreID}`)
			})
	}

	fetchExplore = () => {
		return new Promise((resolve, reject) => {
			ExploreApi.get({ limit: 3, page: 1 })
				.then(async ({ data }) => {
					let articleCards = data.data
					await this.setState({ articleCards }, () => {
						resolve()
					})
				})
				.catch(err => {
					alert('Terjadi kesalahan saat mengambil data highlights')
					reject()
				})

		})
	}

	handleExploreDetail(id) {
		window.open('/explores/detail/' + id, '_self')
	}

	render() {
		const { explore, articleCards } = this.state
		return (
			<div className="explore">
				<Navigation />
				<Container className="content">
					<Row>
						<Col sm="8">
							<h1 className="title">{explore.name}</h1>
						</Col>
					</Row>
					<Row>
						<Col sm="8">
							<p>{explore.createdAt}</p>
						</Col>
						<Col sm="4" className="related-article">
							<p>Wisata Budaya Lainnya</p>
						</Col>
					</Row>
					<Row>
						<Col sm="8" className="main">
							<img className="headline-image" src={explore.image} />
							<div style={{ whiteSpace: 'pre-line', textAlign: 'justify', lineHeight: '1.6' }}>{explore.description}</div>
						</Col>
						<Col sm="4">
							{_.map(articleCards, articleCard => {
								return (
									<div onClick={() => this.handleExploreDetail(articleCard.id)} className="mouse-over">
										<ArticleCard image={articleCard.image} title={articleCard.name} />
									</div>
								)
							})}
						</Col>
					</Row>
				</Container>
				<Footer />
			</div>
		)
	}
}

export default Explore;
