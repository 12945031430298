import React from 'react';
import _ from 'lodash';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import { AiFillMinusSquare, AiFillPlusSquare } from 'react-icons/ai';


class Form1 extends React.Component {
    render() {
        const { modalData, touched, handleInputChange, handleNext, handlePrev, handleInputArrayChange, handleAddArrayChange, handleDeleteArrayChange, typeModal, handleInputCheckboxChange, handleInputCheckboxLabelChange } = this.props
        return (
            <div>
                <h1>Blok I : Identitas Umum Objek Olahraga Tradisional</h1>
                <Form.Group>
                    <Form.Label className="label">Nama Olahraga Tradisional</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Nama Olahraga Tradisional" value={modalData.olahragaTradisional} isInvalid={touched.olahragaTradisional && !modalData.olahragaTradisional} onChange={e => handleInputChange(e, 'olahragaTradisional')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Periode Waktu Kemunculan (abad) <sup>a)</sup></Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Periode Waktu Kemunculan (abad)" value={modalData.periode} isInvalid={touched.periode && !modalData.periode} onChange={e => handleInputChange(e, 'periode')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Etnis yang melaksanakan</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Etnis yang melaksanakan" value={modalData.etnisYangMelaksanakan} isInvalid={touched.etnisYangMelaksanakan && !modalData.etnisYangMelaksanakan} onChange={e => handleInputChange(e, 'etnisYangMelaksanakan')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Deskripsi Olahraga (termasuk aturan main)</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required as="textarea" row={5} placeholder="Deskripsi Olahraga (termasuk aturan main)" value={modalData.deskripsi} isInvalid={touched.deskripsi && !modalData.deskripsi} onChange={e => handleInputChange(e, 'deskripsi')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Deskripsi Lokasi</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required as="textarea" row={5} placeholder="Deskripsi Lokasi" value={modalData.lokasi} isInvalid={touched.lokasi && !modalData.lokasi} onChange={e => handleInputChange(e, 'lokasi')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Perlengkapan Olahraga Tradisional</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required as="textarea" row={5} placeholder="Perlengkapan Olahraga Tradisional" value={modalData.perlengkapan} isInvalid={touched.perlengkapan && !modalData.perlengkapan} onChange={e => handleInputChange(e, 'perlengkapan')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Frekuensi Pelaksanaan saat ini</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} value={modalData.frekuensi} as="select" onChange={e => handleInputChange(e, 'frekuensi')}>
                        <option value="sudah_tidak_dilaksanakan">Sudah tidak dilaksanakan</option>
                        <option value="jarang">Jarang</option>
                        <option value="sering">Sering</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Jumlah orang yang memainkan <sup>a)</sup></Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Jumlah orang yang memainkan" value={modalData.jumlahOrang} isInvalid={touched.jumlahOrang && !modalData.jumlahOrang} onChange={e => handleInputChange(e, 'jumlahOrang')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <p className="description">Catatan: </p>
                <ol type="a">
                    <li className="description">Dapat diisi dengan angka perkiraan (estimasi)</li>
                </ol>
                <Button onClick={handleNext}>Next</Button>
            </div >
        )
    }
}

export default Form1;
