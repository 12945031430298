import Cookies from 'universal-cookie'

export var API_URL = 'http://103.214.112.225:8082/'
// export var API_URL = 'http://localhost:8082/'

export const SITE_COOKIES = {
	SESSIONID: 'sessionId',
	TOKEN: '_token',
	DEBUG: '_debug',
	EMAIL: '_email',
	NAME: '_name'
}

export const COOKIES = new Cookies()
export const SITE_URL = `/`
export const MENU = {
	HOME: `${SITE_URL}`,
	LOGIN: `${SITE_URL}auth/login`,
	DASHBOARD: `${SITE_URL}admin/index`,
}
