import React from 'react';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FaUserCircle } from 'react-icons/fa';

import { COOKIES, SITE_COOKIES } from '../../config'

import './NavigationHome.css';
import logo from 'assets/images/logo.png';
import { PageApi, BACK_TO_LOGIN } from '../../api'

class NavigationHome extends React.Component {
    constructor(props) {
        super(props);
    }

    logout = () => {
        PageApi.logout()
            .then(async () => {
                BACK_TO_LOGIN(true)
            })
            .catch(async err => {
                BACK_TO_LOGIN(true)
            })
    }

    render() {
        return (
            <Navbar className={`navigation-home justify-content-between`} expand="lg">
                <Container fluid="lg">
                    <Navbar.Brand href="/dashboard" className='brand'><img src={logo} /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link href="/dashboard/explores" className="nav-link">Jelajahi</Nav.Link>
                            <Nav.Link href="/dashboard/news" className="nav-link">Berita</Nav.Link>
                            <Nav.Link href="/dashboard/inspirations" className="nav-link">Inspirasi</Nav.Link>
                            <Nav.Link href="/dashboard/borang" className="nav-link">Borang</Nav.Link>
                            {/* <Nav.Link href="/users" className="nav-link">User</Nav.Link> */}
                            <NavDropdown title={<span style={{ marginRight: "5px" }}><FaUserCircle size={20} /> {COOKIES.get(SITE_COOKIES.NAME)}</span>} id="nav-dropdown">
                                <NavDropdown.Item onClick={this.logout}>Logout</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        );
    }
}

export default NavigationHome;
