import React from 'react';
import _ from 'lodash';
import moment from 'moment'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Pagination from "react-js-pagination";
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { AiFillPlusSquare } from 'react-icons/ai'

import Navigation from 'components/NavigationHome';
import Footer from 'components/Footer';
import ListCard from './components/ListCard';
import Choice from './components/Choice';
import NewsModal from './components/Modal';
import NewsModalDelete from './components/ModalDelete';
import CategoryNewsModal from './components/ModalCategory';

import './NewsList.css';

import { NewsApi, CategoryNewsApi } from '../../api'

var idLocale = require('moment/locale/id');
moment.locale('id', idLocale);
class NewsList extends React.Component {
    state = {
        choose: '',
        chooseName: "",
        items: [],
        showModal: false,
        showModalCategory: false,
        dataModalCategory: {
            id: 0,
            name: "",
        },
        dataModal: {
            id: 0,
            categoryNews: {},
            description: "",
            image: "",
            name: "",
            publishedBy: "",
            categoryNewsId: 0,
        },
        modalType: "",
        page: 0,
        count: 0,
        showModalDelete: false,
        showModalCategoryDelete: false,
    }

    componentWillMount() {
        const query = new URLSearchParams(this.props.location.search);
        let type = "category"
        let page = 1
        for (let param of query.entries()) {
            switch (param[0]) {
                case 'type':
                    type = param[1];
                    break;
                case 'page':
                    page = param[1];
                    break;
                default:
                    break;
            }
        }

        let { chooseName } = this.state

        switch (type) {
            case "category":
                chooseName = "Kategori"
                break;
            case "berita":
                chooseName = "Berita"
                break;
            default:
                break;
        }

        this.setState({ choose: type, chooseName, page })
    }

    componentDidMount() {
        // fetch sesuai type
        const { page, choose } = this.state
        if (choose === "category") {
            this.fetchCategoryNews(page)
        } else {
            this.fetchNews(page)
        }
    }

    fetch = () => {
        const { page, choose } = this.state
        if (choose === 'category') {
            this.fetchCategoryNews(page)
        } else {
            this.fetchNews(page)

        }
    }

    fetchNews = (page) => {
        return new Promise((resolve, reject) => {
            NewsApi.get({ limit: 5, page: page })
                .then(async ({ data }) => {
                    let items = data.data
                    let count = data.count
                    await this.setState({ items, count }, () => {
                        resolve()
                    })
                })
                .catch(err => {
                    alert(`Terjadi kesalahan saat mengambil data berita ${err}`)
                    reject()
                })

        })
    }

    fetchCategoryNews = (page) => {
        return new Promise((resolve, reject) => {
            CategoryNewsApi.get({ limit: 10, page: page })
                .then(async ({ data }) => {
                    let items = data.data
                    let count = data.count
                    await this.setState({ items, count }, () => {
                        resolve()
                    })
                })
                .catch(err => {
                    alert(`Terjadi kesalahan saat mengambil data kategory berita ${err}`)
                    reject()
                })

        })
    }

    changeChoose(choose) {
        window.open('/dashboard/news?type=' + choose, '_self')
    }

    handlePageChange(pageNumber) {
        const queryParams = [];
        const { choose } = this.state
        queryParams.push('type=' + choose);
        queryParams.push('page=' + pageNumber)
        const queryString = queryParams.join('&');
        window.open('/dashboard/news?' + queryString, '_self')
    }

    handleCloseModal = () => {
        const { choose } = this.state
        if (choose === "category") {
            this.setState({ showModalCategory: false, dataModalCategory: {} })
        } else {
            this.setState({ showModal: false, dataModal: {} })
        }
    }

    handleOpenModal = () => {
        const { choose } = this.state
        if (choose === "category") {
            this.setState({ showModalCategory: true, modalType: "add" })
        } else {
            this.setState({ showModal: true, modalType: "add" })
        }
    }

    handleEditModal = (data) => {
        let { modalType, choose } = this.state
        modalType = "edit"
        if (choose === "category") {
            let { showModalCategory } = this.state
            showModalCategory = true
            this.setState({ showModalCategory, dataModalCategory: data, modalType })
        } else {
            let { showModal } = this.state
            showModal = true
            this.setState({ showModal, dataModal: data, modalType })
        }
    }

    handleShowModalDelete = (dataModal) => {
        let { choose } = this.state
        if (choose === 'category') {
            this.setState({ showModalCategoryDelete: true, dataModalCategory: dataModal })
        } else {
            this.setState({ showModalDelete: true, dataModal })
        }
    }

    handleCloseModalDelete = () => {
        let { choose } = this.state
        if (choose === 'category') {
            this.setState({ showModalCategoryDelete: false, dataModalCategory: {} })
        } else {
            this.setState({ showModalDelete: false, dataModal: {} })
        }
    }

    handleDelete = (id) => {
        let { choose } = this.state
        if (choose === 'category') {
            CategoryNewsApi.delete(id)
                .then(response => {
                    alert("Kategori Berita berhasil dihapus!")
                    setTimeout(() => {
                        this.setState({ showModalDelete: false })
                        this.fetch()
                    }, 300)
                })
                .catch(err => {
                    alert(`Terjadi kesalahan pada hapus kategori berita ${err}`)
                })
        } else {
            NewsApi.delete(id)
                .then(response => {
                    alert("Berita berhasil dihapus!")
                    setTimeout(() => {
                        this.setState({ showModalDelete: false })
                        this.fetch()
                    }, 300)
                })
                .catch(err => {
                    alert(`Terjadi kesalahan pada hapus berita ${err}`)
                })
        }
    }

    render() {
        let i = 0
        let { choose, chooseName, count, page, items } = this.state;
        let { showModal, dataModal, modalType, showModalDelete } = this.state
        let { showModalCategory, dataModalCategory, showModalCategoryDelete } = this.state
        return (
            <div className="news-list" style={{minWidth: "1200px"}}>
                <Navigation />
                <Container className="content">
                    <Row>
                        <Col xs={6} sm={6}>
                            <h1 className="title">Berita</h1>
                        </Col>
                    </Row>
                    <Choice changeChoose={this.changeChoose} choose={choose} />
                    {
                        choose !== 'category' ? <Row className="justify-content-end containerButton">
                            <Button className="buttonAdd" onClick={this.handleOpenModal}><AiFillPlusSquare size={20} color="white" style={{ marginRight: "5px" }} />Tambah {chooseName}</Button>
                        </Row> : null
                    }

                    {
                        choose !== 'category' ? _.map(items, item => {
                            i++
                            return (
                                <div>
                                    <ListCard
                                        item={item}
                                        type="image"
                                        handleEdit={this.handleEditModal}
                                        handleDelete={this.handleDelete}
                                        handleShowModalDelete={this.handleShowModalDelete}
                                    />
                                    {i < 5 ? <hr style={{ marginBottom: "40px" }} /> : null}
                                </div>
                            )
                        }) :
                            <Row style={{marginTop: "30px"}}>
                                <Col xs={8} lg={8}>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Nama</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {_.map(items, item => {
                                                return (
                                                    <tr>
                                                        <td>{item.id}</td>
                                                        <td>{item.name}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </Col>
                                <Col xs={4} lg={4}>
                                    <Button className="buttonAdd" style={{marginBottom: "6px", marginLeft: "15px", width: "85%"}} onClick={this.handleOpenModal}><AiFillPlusSquare size={20} color="white" style={{ marginRight: "5px" }} />Tambah {chooseName}</Button>
                                    {_.map(items, item => {
                                        return (
                                                <Button onClick={() => this.handleEditModal(item)} className="buttonAdd" style={{ marginTop: "11px", marginLeft: "15px", width: "85%" }}>Ubah</Button>
                                        )
                                    })}
                                </Col>
                            </Row>
                    }
                    {showModal && <NewsModal
                        show={showModal}
                        handleClose={this.handleCloseModal}
                        callback={this.fetch}
                        modalType={modalType}
                        dataModal={dataModal}
                    />}
                    {showModalCategory && <CategoryNewsModal
                        show={showModalCategory}
                        handleClose={this.handleCloseModal}
                        callback={this.fetch}
                        modalType={modalType}
                        dataModal={dataModalCategory}
                    />}
                    {count > 0 ?
                        <Row>
                            <Col>
                                <Pagination
                                    activePage={page}
                                    itemsCountPerPage={10}
                                    totalItemsCount={count}
                                    pageRangeDisplayed={10}
                                    onChange={this.handlePageChange.bind(this)}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                />
                            </Col>
                        </Row> : null
                    }
                    {showModalDelete && <NewsModalDelete
                        show={showModalDelete}
                        handleDelete={this.handleDelete}
                        handleClose={this.handleCloseModalDelete}
                        dataModal={dataModal}
                    />}
                </Container>
                <Footer />
            </div>
        );
    }
}

export default NewsList;
