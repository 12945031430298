import React from 'react';
import _ from 'lodash';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import { AiFillMinusSquare, AiFillPlusSquare } from 'react-icons/ai';


class Form1 extends React.Component {
    render() {
        const { modalData, touched, handleInputChange, handleNext, handlePrev, handleInputArrayChange, handleAddArrayChange, handleDeleteArrayChange, typeModal } = this.props
        return (
            <div>
                <h1>Blok I : Identitas Umum Objek Ritus</h1>
                <Form.Group>
                    <Form.Label className="label">Nama Ritus</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Nama Ritus" value={modalData.ritus} isInvalid={touched.ritus && !modalData.ritus} onChange={e => handleInputChange(e, 'ritus')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Konteks Adat Istiadat</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Konteks Adat Istiadat" value={modalData.konteksAdatIstiadat} isInvalid={touched.konteksAdatIstiadat && !modalData.konteksAdatIstiadat} onChange={e => handleInputChange(e, 'konteksAdatIstiadat')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Etnis yang melaksanakan</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Etnis yang melaksanakan" value={modalData.etnisYangMelaksanakan} isInvalid={touched.etnisYangMelaksanakan && !modalData.etnisYangMelaksanakan} onChange={e => handleInputChange(e, 'etnisYangMelaksanakan')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Lokasi</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Lokasi" value={modalData.lokasi} isInvalid={touched.lokasi && !modalData.lokasi} onChange={e => handleInputChange(e, 'lokasi')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Komponen Pelaku Ritus</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Komponen Pelaku Ritus" value={modalData.komponenPelakuRitus} isInvalid={touched.komponenPelakuRitus && !modalData.komponenPelakuRitus} onChange={e => handleInputChange(e, 'komponenPelakuRitus')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Perlengkapan Ritus</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Perlengkapan Ritus" value={modalData.perlengkapanRitus} isInvalid={touched.perlengkapanRitus && !modalData.perlengkapanRitus} onChange={e => handleInputChange(e, 'perlengkapanRitus')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Frekuensi Pelaksanaan saat ini</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} value={modalData.frekuensi} as="select" onChange={e => handleInputChange(e, 'frekuensi')}>
                        <option value="sudah_tidak_dilaksanakan">Sudah tidak dilaksanakan</option>
                        <option value="jarang">Jarang</option>
                        <option value="sering">Sering</option>
                    </Form.Control>
                </Form.Group>
                <Button onClick={handleNext}>Next</Button>
            </div >
        )
    }
}

export default Form1;
