import React from 'react';
import _ from 'lodash';
import moment from 'moment'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

import Navigation from '../../components/Navigation';
import Footer from '../../components/Footer';

import Choice from './components/Choice';

import './ExploreList.css';

import { ExploreApi } from '../../api'

var idLocale = require('moment/locale/id');
moment.locale('id', idLocale);
class ExploreList extends React.Component {
    state = {
        choose: '',
        items: [],
        categoryCultureId: 0,
    }

    componentWillMount() {
        const query = new URLSearchParams(this.props.location.search);
        let type = "wisata"
        for (let param of query.entries()) {
            switch (param[0]) {
                case 'type':
                    type = param[1];
                    break;
                default:
                    break;
            }
        }

        let { categoryCultureId } = this.state

        switch (type) {
            case "wisata":
                categoryCultureId = 1
                break;
            case "budaya":
                categoryCultureId = 2
                break;
            case "heritage":
                categoryCultureId = 3
                break;
            case "museum":
                categoryCultureId = 4
                break;
            case "kesenian":
                categoryCultureId = 5
                break;
            default:
                break;
        }

        this.setState({ choose: type, categoryCultureId })
    }

    componentDidMount() {
        // fetch sesuai type
        const {categoryCultureId} = this.state
        this.fetchExplores(categoryCultureId)
    }

    fetchExplores = (categoryCultureId) => {
        return new Promise((resolve, reject) => {
            ExploreApi.get({ limit: 0, page: 0, categoryCultureId: categoryCultureId })
                .then(async ({ data }) => {
                    let items = data.data
                    await this.setState({ items }, () => {
                        resolve()
                    })
                })
                .catch(err => {
                    alert('Terjadi kesalahan saat mengambil data jelajahi')
                    reject()
                })

        })
    }

    changeChoose(choose) {
        window.open('/explores?type=' + choose, '_self')
    }

    handleExploresDetail(id) {
		window.open('/explores/detail/' + id, '_self')
	}

    render() {
        let { choose, items } = this.state;
        return (
            <div className="explore-list">
                <Navigation />
                <Container className="content">
                    <Row>
                        <Col sm={6}>
                            <h1 className="title">Jelajahi Makassar</h1>
                        </Col>
                    </Row>
                    <Choice changeChoose={this.changeChoose} choose={choose} />
                    <Row style={{ marginTop: "66px", paddingLeft: "10px" }}>
                        {
                            _.map(items, item => {
                                return (
                                    <Col lg={3} className="cardStyle" onClick={() => this.handleExploresDetail(item.id)}>
                                        <Card>
                                            <Card.Img variant="top" src={item.image}/>
                                            <Card.Body>
                                                <p className="category-card">#{item.categoryCulture.name.toUpperCase()}</p>
                                                <p className="title-card">{item.name}</p>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Container>
                <Footer />
            </div>
        );
    }
}

export default ExploreList;
