import React from 'react';
import _ from 'lodash';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import { AiFillMinusSquare, AiFillPlusSquare } from 'react-icons/ai';


class Form1 extends React.Component {
    render() {
        const { modalData, touched, handleInputChange, handleNext, handlePrev, handleInputArrayChange, handleAddArrayChange, handleDeleteArrayChange, typeModal, handleInputCheckboxChange, handleInputCheckboxLabelChange } = this.props
        return (
            <div>
                <h1>Blok I : Identitas Umum Objek Seni</h1>
                <Form.Group>
                    <Form.Label className="label">Nama Karya</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Nama Karya" value={modalData.seniUmum} isInvalid={touched.seniUmum && !modalData.seniUmum} onChange={e => handleInputChange(e, 'seniUmum')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Pencipta (jika ada)</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Pencipta" value={modalData.pencipta} isInvalid={touched.pencipta && !modalData.pencipta} onChange={e => handleInputChange(e, 'pencipta')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Cabang Seni</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} value={modalData.cabangSeni} as="select" onChange={e => handleInputChange(e, 'cabangSeni')}>
                        <option value="seni_teater">Seni Teater</option>
                        <option value="seni_sastra">Seni Sastra</option>
                        <option value="seni_musik">Seni Musik</option>
                        <option value="seni_film">Seni Film</option>
                        <option value="seni_rupa">Seni Rupa</option>
                        <option value="seni_tari">Seni Tari</option>
                        <option value="seni_media">Seni Media</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Jumlah karya yang dipertunjukkan dalam setahun terakhir</Form.Label>
                    <Form.Control disabled={typeModal === 'edit' || !(modalData.cabangSeni === 'seni_teater' || modalData.cabangSeni === 'seni_musik' || modalData.cabangSeni === 'seni_tari')} required type="text" placeholder="Jumlah karya yang dipertunjukkan dalam setahun terakhir" value={modalData.jumlahPertunjukan} isInvalid={touched.jumlahPertunjukan && (modalData.cabangSeni === 'seni_teater' || modalData.cabangSeni === 'seni_musik' || modalData.cabangSeni === 'seni_tari') && !modalData.jumlahPertunjukan} onChange={e => handleInputChange(e, 'jumlahPertunjukan')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Jumlah karya di ruang publik dalam setahun terakhir</Form.Label>
                    <Form.Control disabled={typeModal === 'edit' || !(modalData.cabangSeni === 'seni_rupa')} required type="text" placeholder="Jumlah karya di ruang publik dalam setahun terakhir" value={modalData.jumlahPublik} isInvalid={touched.jumlahPublik && (modalData.cabangSeni === 'seni_rupa') && !modalData.jumlahPublik} onChange={e => handleInputChange(e, 'jumlahPublik')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Jumlah pemutaran dalam setahun terakhir</Form.Label>
                    <Form.Control disabled={typeModal === 'edit' || !(modalData.cabangSeni === 'seni_film')} required type="text" placeholder="Jumlah pemutaran dalam setahun terakhir" value={modalData.jumlahPemutaran} isInvalid={touched.jumlahPemutaran && (modalData.cabangSeni === 'seni_film') && !modalData.jumlahPemutaran} onChange={e => handleInputChange(e, 'jumlahPemutaran')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Jumlah Pelaku Seni <sup>a)</sup></Form.Label>
                    <Form.Control disabled={typeModal === 'edit' || !(modalData.cabangSeni === 'seni_media' || modalData.cabangSeni === 'seni_sastra')} required type="text" placeholder="Jumlah Pelaku Seni" value={modalData.jumlahPelaku} isInvalid={touched.jumlahPelaku && (modalData.cabangSeni === 'seni_media' || modalData.cabangSeni === 'seni_sastra') && !modalData.jumlahPelaku} onChange={e => handleInputChange(e, 'jumlahPelaku')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Jumlah Masyarakat Pendukung <sup>a)</sup></Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Jumlah Masyarakat Pendukung" value={modalData.jumlahMasyarakat} isInvalid={touched.jumlahMasyarakat && !modalData.jumlahMasyarakat} onChange={e => handleInputChange(e, 'jumlahMasyarakat')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Jika tidak dapat menyebutkan jumlah pertunjukan dan jumlah pemutaran<br/><br/>Frekuensi Pemanfaatan Saat Ini</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} value={modalData.frekuensi} as="select" onChange={e => handleInputChange(e, 'frekuensi')}>
                        <option value="sudah_tidak_dilaksanakan">Sudah tidak dilaksanakan</option>
                        <option value="jarang">Jarang</option>
                        <option value="sering">Sering</option>
                    </Form.Control>
                </Form.Group>
                <p className="description">Catatan: </p>
                <ol type="a">
                    <li className="description">Dapat diisi dengan angka perkiraan (estimasi)</li>
                </ol>
                <Button onClick={handleNext}>Next</Button>
            </div >
        )
    }
}

export default Form1;
