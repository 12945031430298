import React from 'react';
import _ from 'lodash';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import { AiFillMinusSquare, AiFillPlusSquare } from 'react-icons/ai';


class Form1 extends React.Component {
    render() {
        const { modalData, touched, handleInputChange, handleNext, handlePrev, handleInputArrayChange, handleAddArrayChange, handleDeleteArrayChange, typeModal } = this.props
        return (
            <div>
                <h1>Blok I : Identitas Umum Objek Bahasa</h1>
                <Form.Group>
                    <Form.Label className="label">Bahasa yang teridentifikasi di Kabupaten/Kota</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Bahasa yang teridentifikasi di Kabupaten/Kota" value={modalData.bahasa} isInvalid={touched.bahasa && !modalData.bahasa} onChange={e => handleInputChange(e, 'bahasa')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Jenis aksara yang digunakan</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Jenis aksara yang digunakan" value={modalData.aksara} onChange={e => handleInputChange(e, 'aksara')} isInvalid={touched.aksara && !modalData.aksara} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Apakah bahasa tersebut masih digunakan saat ini?</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} value={modalData.apakahMasihDigunakan} as="select" onChange={e => handleInputChange(e, 'apakahMasihDigunakan')}>
                        <option value={true}>Ya</option>
                        <option value={false}>Tidak</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Jenis Dialek yang berhubungan dengan Bahasa tersebut (Tambahkan baris apabila terdapat lebih dari 1 jenis dialek)</Form.Label>
                    <Table>
                        <thead>
                            <tr>
                                <th style={{ width: "25%" }}>Nama Dialek</th>
                                <th style={{ width: "30%" }}>Jumlah Penutur <sup>(a</sup></th>
                                <th style={{ width: "40%" }}>Kelompok Etnis Penutur <sup>(b</sup></th>
                                <th style={{ width: "5%" }}><Button disabled={modalData.apakahMasihDigunakan === 'false' || typeModal === 'edit'} onClick={(e) => handleAddArrayChange(e, 'dialek')}><AiFillPlusSquare size={20} color="white" style={{ marginRight: "5px" }} /></Button></th>
                            </tr>
                        </thead>
                        <tbody>
                            {_.map(modalData.dialekList, (item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <Form.Group>
                                                <Form.Control disabled={typeModal === 'edit'} type="text" placeholder="Nama Dialek" value={item.namaDialek} onChange={e => handleInputArrayChange(e, 'dialek', 'namaDialek', index)} isInvalid={!item.namaDialek}></Form.Control>
                                            </Form.Group>
                                        </td>
                                        <td>
                                            <Form.Group>
                                                <Form.Control disabled={typeModal === 'edit'} type="text" placeholder="Jumlah Penutur" value={item.jumlahPenutur} onChange={e => handleInputArrayChange(e, 'dialek', 'jumlahPenutur', index)} isInvalid={!item.jumlahPenutur}></Form.Control>
                                            </Form.Group>
                                        </td>
                                        <td>
                                            <Form.Group>
                                                <Form.Control disabled={typeModal === 'edit'} type="text" placeholder="Kelompok Etnis Penutur" value={item.jumlahKelompokEtnis} onChange={e => handleInputArrayChange(e, 'dialek', 'jumlahKelompokEtnis', index)} isInvalid={!item.jumlahKelompokEtnis}></Form.Control>
                                            </Form.Group>
                                        </td>
                                        <td><Button disabled={typeModal === 'edit'} onClick={(e) => handleDeleteArrayChange(e, 'dialek', index)}><AiFillMinusSquare size={20} color="white" style={{ marginRight: "5px" }} /></Button></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                    <p className="description">a) Dapat diisi dengan angka perkiraan (estimasi)</p>
                    <p className="description">b) Jika terdapat lebih dari satu etnis penutur dialek, dipisahkan dengan tanda titik koma (;)</p>
                </Form.Group>
                <Button onClick={handleNext}>Next</Button>
            </div >
        )
    }
}

export default Form1;
