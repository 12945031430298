import React from 'react';
import _ from 'lodash';
import moment from 'moment'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import CardHighlights from '../../../../components/CardHighlights';
import './Highlights.css';
import dummyImage from '../../../../assets/images/dummy.png';
import { NavLink } from 'react-router-dom'

import { NewsApi } from '../../../../api'

var idLocale = require('moment/locale/id'); 
moment.locale('id', idLocale);

class Highlights extends React.Component {
	state = {
		cardsHighlights: [],
	}

	componentDidMount() {
		this.fetchNews()
	}

	fetchNews = () => {
		return new Promise((resolve, reject) => {
			NewsApi.get({ limit: 3, page: 1 })
				.then(async ({ data }) => {
					let cardsHighlights = data.data
					await this.setState({ cardsHighlights }, () => {
						resolve()
					})
				})
				.catch(err => {
					alert('Terjadi kesalahan saat mengambil data highlights')
					reject()
				})

		})
	}

	moreNews = () => {

	}

	render() {
		const {cardsHighlights} = this.state

		return (
			<div className="highlights">
				<Container>
					<Row className="justify-content-between highlights-top">
						<Col className="title-highlights">
							<h2>Highlights</h2>
							<h4>Dapatkan update terkini mengenai Kota Makassar</h4>
						</Col>
						<Col className="news" >
							<a href="/news"><h4>Lihat semua berita ></h4></a>
						</Col>
					</Row>
					<Row>
						{_.map(cardsHighlights, cardHighlights => {
							return (
								<Col sm="4">
									<CardHighlights image={cardHighlights.image} type={cardHighlights.categoryNews.name} title={cardHighlights.name} date={moment(cardHighlights.createdAt).format('DD MMMM YYYY')} />
								</Col>
							);
						})}
					</Row>
				</Container>
			</div>
		);
	}
}

export default Highlights;
