import React from 'react';
import _ from 'lodash';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import { AiFillMinusSquare, AiFillPlusSquare } from 'react-icons/ai';


class Form4 extends React.Component {
    render() {
        const { modalData, touched, handleInputChange, handleNext, handlePrev, handleInputArrayChange, handleAddArrayChange, handleDeleteArrayChange, handleSubmit, typeModal } = this.props
        return (
            <div>
                <h1>Blok IV : Identifikasi Masalah Objek Tradisi Lisan</h1>
                <Form.Group>
                    <Form.Label className="label">Tuliskan dalam tabel di bawah ini masalah, upaya yang telah dilakukan, serta kendala yang dihadapi dalam pelindungan, pengembangan, pemanfaatan dan pembinaan tradisi lisan yang bersangkutan.</Form.Label>
                    <p className="description">Catatan : Satu baris hanya mewakili 1 jenis masalah. Apabila terdapat lebih dari 1 jenis masalah dapat ditambahkan baris pada tabel tersebut. </p>
                    <Table>
                        <thead>
                            <tr>
                                <th style={{ width: "25%" }}>Masalah</th>
                                <th style={{ width: "30%" }}>Upaya yang telah dilakukan <sup>a)</sup></th>
                                <th style={{ width: "40%" }}>Kendala yang dihadapi atas upaya tersebut <sup>a)</sup></th>
                                <th style={{ width: "5%" }}><Button disabled={typeModal === 'edit'} onClick={(e) => handleAddArrayChange(e, 'masalah')}><AiFillPlusSquare size={20} color="white" style={{ marginRight: "5px" }} /></Button></th>
                            </tr>
                        </thead>
                        <tbody>
                            {_.map(modalData.masalahList, (item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <Form.Group>
                                                <Form.Control disabled={typeModal === 'edit'} type="text" placeholder="Masalah" value={item.masalah} onChange={e => handleInputArrayChange(e, 'masalah', 'masalah', index)} isInvalid={!item.masalah}></Form.Control>
                                            </Form.Group>
                                        </td>
                                        <td >
                                            <Form.Group>
                                                <Form.Control disabled={typeModal === 'edit'} type="text" placeholder="Upaya yang telah dilakukan" value={item.upayaYangDilakukan} onChange={e => handleInputArrayChange(e, 'masalah', 'upayaYangDilakukan', index)} isInvalid={!item.upayaYangDilakukan}></Form.Control>
                                            </Form.Group>
                                        </td>
                                        <td>
                                            <Form.Group>
                                                <Form.Control disabled={typeModal === 'edit'} type="text" placeholder="Kendala yang dihadapi atas upaya tersebut" value={item.kendalaYangDihadapi} onChange={e => handleInputArrayChange(e, 'masalah', 'kendalaYangDihadapi', index)} isInvalid={!item.kendalaYangDihadapi}></Form.Control>
                                            </Form.Group>
                                        </td>
                                        <td><Button disabled={typeModal === 'edit'} onClick={(e) => handleDeleteArrayChange(e, 'masalah', index)}><AiFillMinusSquare size={20} color="white" style={{ marginRight: "5px" }} /></Button></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Form.Group>
                <p className="description">Catatan:</p>
                <p className="description">a) Jika terdapat lebih dari satu, dipisahkan dengan tanda titik koma (;)</p>
                <Button onClick={handlePrev} style={{ marginRight: "10px", marginTop: "10px" }}>Prev</Button>
                <Button onClick={handleSubmit} style={{ marginRight: "10px", marginTop: "10px" }}>{`${typeModal === "edit" ? `List Borang` : `Submit`}`}</Button>
            </div >
        )
    }
}

export default Form4;
