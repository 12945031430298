import React from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';

import { getCookie, setCookie } from '../../utils/cookies'
import { SITE_COOKIES, MENU } from '../../config';
import { UserContext } from '../../context/user-context/UserGlobalState'
import { PageApi } from '../../api';

import logo from '../../assets/images/logo@3x.png';
import './Login.css';


export default class Login extends React.Component {
    state = {
        formLogin: {
            email: "",
            password: "",
        }
    }

    componentDidMount() {
        this.isLogin();
    }

    isLogin = () => {
        if (getCookie(SITE_COOKIES.SESSIONID)) {
            this.props.history.push(`${MENU.DASHBOARD_EXPLORE}`)
        }
    }

    handleFormOnChange = (event) => {
        let formLoginNew = { ...this.state.formLogin };
        formLoginNew[event.target.name] = event.target.value;
        this.setState({
            formLogin: formLoginNew
        })
    }

    handleSubmitOnClick = e => {
        e.preventDefault()
        console.log('asdkfj')
        PageApi.login(this.state.formLogin)
            .then(({ data }) => {
                this.context.setUserData(data)
                setCookie(SITE_COOKIES.SESSIONID, data.sessionId, 1)
                setCookie(SITE_COOKIES.NAME, data.user.name, 1)
                setCookie(SITE_COOKIES.EMAIL, data.user.email, 1)
                setCookie(SITE_COOKIES.DEBUG, window.location.protocol === 'https:' ? false : true, 1)
                this.props.history.push(`${MENU.DASHBOARD_EXPLORE}`)
            })
            .catch(err => {
                alert("Email atau password salah !!")
                this.setState()
            })
    }

    render() {
        return (
            <div className="login">
                <Container>
                    <div className="logo">
                        <img src={logo} />
                    </div>
                    <h1>Log In</h1>
                    <h3>Welcome back, please login to your account</h3>
                    <Form onSubmit={this.handleSubmitOnClick}>
                        <Form.Group controlId="formBasicEmail" style={{ width: "60%", marginLeft: "auto", marginRight: "auto" }}>
                            <Form.Control name="email" type="email" placeholder="Enter email" onChange={this.handleFormOnChange}/>
                        </Form.Group>
                        <Form.Group controlId="formBasicPassword" style={{ width: "60%", marginLeft: "auto", marginRight: "auto" }}>
                            <Form.Control name="password" type="password" placeholder="Password" onChange={this.handleFormOnChange}/>
                        </Form.Group>
                        <Row className="justify-content-center">
                            <Button variant="warning" type="submit" style={{ width: "58%" }}>Submit</Button>
                        </Row>
                    </Form>
                </Container>
            </div>
        )

    }
}

Login.contextType = UserContext
