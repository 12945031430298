import React from 'react';
import Container from 'react-bootstrap/Container';

import Navigation from '../../components/Navigation';
import Footer from '../../components/Footer';
import Choice from './components/Choice';
import Banner from './components/Banner';
import Content from './components/Content';

class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      choose: 'about'
    }

    this.changeChoose = this.changeChoose.bind(this);
  }

  changeChoose(choose) {
    this.setState({
      choose
    })
  }

  render() {
    let { choose } = this.state;

    return (
      <div className="profile">
        <Navigation />
        <Choice changeChoose={this.changeChoose} choose={choose}/>
        {/* <Banner /> */}
        <Content choose={choose}/>
        <Footer />
      </div>
    );
  }
}

export default Profile;
