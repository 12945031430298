/* eslint-disable */
/* eslint-enable */

import axios from 'axios'

export default ({ url, config }) => {
  const endpoint = 'v1/users'
  return {
    get: params => axios.get(`${url}${endpoint}`, { ...config({type: 'dashboard'}), params: { ...params } }),
    create: data => axios.post(`${url}${endpoint}`, data, config({type: 'dashboard'})),
    update: (id, data) => axios.put(`${url}${endpoint}/${id}`, data, config({type: 'dashboard'})),
    delete: id => axios.delete(`${url}${endpoint}/${id}`, { ...config({type: 'dashboard'})}),
  }
}