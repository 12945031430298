import React from 'react';
import _ from 'lodash';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import { AiFillMinusSquare, AiFillPlusSquare } from 'react-icons/ai';


class Form2 extends React.Component {
    render() {
        const { modalData, touched, handleInputChange, handleNext, handlePrev, handleInputArrayChange, handleAddArrayChange, handleDeleteArrayChange, typeModal } = this.props
        return (
            <div>
                <h1>Blok II : Identifikasi Lembaga dan Sarana-Prasarana Objek Seni Tari</h1>
                <Form.Group>
                    <Form.Label className="label">Daftar Lembaga yang lingkup kegiatannya berhubungan dengan objek seni tari tersebut. <strong>Jika terdapat lebih dari satu lembaga, dipisahkan dengan tanda titik koma (;)</strong></Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required as="textarea" placeholder="Daftar Lembaga" row={5} value={modalData.lembaga} isInvalid={touched.lembaga && !modalData.lembaga} onChange={e => handleInputChange(e, 'lembaga')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <p className="label" style={{ marginBottom: "0px" }}>Daftar sarana dan prasarana (sarpras) fisik yang digunakan dalam pemajuan objek bahasa tersebut. <strong>Jika masing-masing terdapat lebih dari satu bentuk sarpras, dipisahkan dengan tanda titik koma (;)</strong></p>
                <Form.Group>
                    <Form.Label className="label" style={{ marginTop: "10px" }}>Sarpras Masyarakat</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required as="textarea" placeholder="Sarpras Masyarakat" row={5} value={modalData.sarprasMasyarakat} isInvalid={touched.sarprasMasyarakat && !modalData.sarprasMasyarakat} onChange={e => handleInputChange(e, 'sarprasMasyarakat')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label" style={{ marginTop: "10px" }}>Sarpras Pemerintah</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required as="textarea" placeholder="Sarpras Pemerintah" row={5} value={modalData.sarprasPemerintah} isInvalid={touched.sarprasPemerintah && !modalData.sarprasPemerintah} onChange={e => handleInputChange(e, 'sarprasPemerintah')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Button onClick={handlePrev} style={{ marginRight: "10px" }}>Prev</Button>
                <Button onClick={handleNext}>Next</Button>
            </div >
        )
    }
}

export default Form2;
