import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import './Contact.css';

class Contact extends React.Component {
  render() {
    return (
      <div className="contact">
        <Container>
          <Row>
            <Col sm="4">
              <h1>Punya saran?</h1>
            </Col>
            <Col sm="4">
            </Col>
            <Col sm="4">

            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Contact;
