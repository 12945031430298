/* eslint-disable */
/* eslint-enable */

import axios from 'axios'

export default ({ url, config }) => {
  const endpoint = 'v1/upload'
  return {
    upload: file => axios.post(`${url}${endpoint}`, file, config({type: 'dashboard'})),
  }
}
