import React from 'react'

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { BsCameraVideoFill, BsImageFill } from 'react-icons/bs'
import { AiFillCloseCircle } from 'react-icons/ai';

import { UploadApi, InspirationApi } from '../../../../api'

import './Modal.css'

export default class InspirationModal extends React.Component {
    state = {
        previewImage: null,
        previewVisible: false,
        fileName: "",
        modalData: {
            id: 0,
            type: "",
            file: {},
            name: "",
            description: "",
            image: "",
        },
        validated: false,
        loading: false,
    }

    componentDidMount() {
        let { modalData, previewImage, previewVisible } = this.state
        let { type } = this.props
        if (this.props.modalType === "edit") {
            const { dataModal } = this.props
            modalData.file = {}
            modalData.name = dataModal.name
            modalData.description = dataModal.description
            modalData.image = dataModal.image
            modalData.id = dataModal.id
            previewImage = dataModal.image
            previewVisible = true
        }
        modalData.type = type
        this.setState({ modalData, previewImage, previewVisible })
    }

    setValidated(data) {
        let { validated } = this.state
        validated = data
        this.setState({ validated })
    }

    uploadProgress = (event) => {
        event.preventDefault()
        let { modalData } = this.state
        if (event.target.files[0].type.includes(modalData.type)) {
            const file = URL.createObjectURL(event.target.files[0])
            modalData.file = event.target.files[0]
            this.setState({
                previewImage: file,
                previewVisible: true,
                fileName: event.target.files[0].name,
                modalData,
            })
        } else {
            alert('File tidak sesuai format')
        }
    }

    removeUploadedFile = () => {
        let { modalData, previewImage, previewVisible, fileName } = this.state
        modalData.file = {}
        previewImage = null
        previewVisible = false
        fileName = ""
        this.setState({
            modalData,
            previewImage,
            previewVisible,
            fileName,
        })
    }

    getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    uploadFile = () => {
        let { modalData } = this.state
        if (modalData.hasOwnProperty('file')) {
            let file = modalData.file
            const payload = new FormData()
            if (modalData.file && modalData.file.size > 0) payload.append('uploadFile', file)
            return UploadApi.upload(payload)
        }
    }

    handleInputChange = (event, field) => {
        event.preventDefault()
        let { modalData } = this.state
        const data = event.target.value
        modalData[field] = data
        this.setState({ modalData })
    }

    submit = (event) => {
        this.setState({ loading: true })
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (form.checkValidity() === true) {
            event.preventDefault()
            let { modalData } = this.state
            if (modalData.file.size > 0) {
                const upload = this.uploadFile()
                upload.then(response => {
                    modalData.image = response.data.url
                    this.submitInspiration(modalData)
                }).catch(err => {
                    alert(err.message)
                })
            } else {
                this.submitInspiration(modalData)
            }
        }
        this.setValidated(true);
    }

    submitInspiration = (data) => {
        const { modalType, type } = this.props
        if (modalType === "edit") {
            InspirationApi.update(data.id, data)
                .then(response => {
                    alert("Inspiration berhasil diupdate!")
                    setTimeout(() => {
                        this.setState({ loading: false })
                        this.props.callback()
                        this.props.handleClose()
                    }, 300)
                })
                .catch(err => {
                    this.setState({ loading: false })
                    alert(`Terjadi kesalahan pada update ${type} ${err}`)
                })
        } else {
            InspirationApi.create(data)
                .then(response => {
                    alert("Inspiration berhasil ditambah!")
                    setTimeout(() => {
                        this.setState({ loading: true })
                        this.props.callback()
                        this.props.handleClose()
                    }, 300)
                })
                .catch(err => {
                    this.setState({ loading: false })
                    alert(`Terjadi kesalahan pada tambah ${type} ${err}`)
                })
        }

    }

    render() {
        const { show, handleClose, modalType, type } = this.props
        let { previewVisible, previewImage, fileName, modalData, validated, loading } = this.state
        if (fileName === "") {
            if (type === 'video') {
                fileName = "Masukkan Video"
            } else {
                fileName = "Masukkan Gambar"
            }
        }
        let preview = null
        let previewNull = null
        if (type === 'video') {
            preview = <embed type="video/webm" src={previewImage} className="image-preview" />
            previewNull = <BsCameraVideoFill size={70} color="#f29e20" style={{ margin: "auto auto" }} />
        } else {
            preview = <img src={previewImage} className="image-preview" />
            previewNull = <BsImageFill size={70} color="#f29e20" style={{ margin: "auto auto" }} />
        }
        return (
            <div className="inspiration-modal">
                <Modal show={show} onHide={handleClose} size="xl" centered>
                    <Form noValidate validated={validated} onSubmit={this.submit}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {`${modalType === "edit" ? `Ubah ${type}` : `Tambah ${type}`}`}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col xs={7} lg={7} className="container-left">
                                    <Form.File id="custom-file" label={fileName} custom onChange={this.uploadProgress} />
                                    {!previewVisible && <div className="container-image">
                                        {previewNull}
                                    </div>}
                                    {previewVisible && <div className="container-previewImage">
                                        <AiFillCloseCircle color="#f29e20" onClick={this.removeUploadedFile} />
                                        {preview}
                                    </div>}
                                </Col>
                                <Col xs={5} lg={5}>
                                    <Form.Group>
                                        <Form.Label>Nama</Form.Label>
                                        <Form.Control required type="text" placeholder="Nama" value={modalData.name} onChange={e => this.handleInputChange(e, 'name')} />
                                        <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Deskripsi</Form.Label>
                                        <Form.Control as="textarea" rows="8" placeholder="Deskripsi" onChange={e => this.handleInputChange(e, 'description')} value={modalData.description} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button disabled={loading} variant="secondary" type="submit">{`${modalType === "edit" ? `Ubah` : `Tambah`}`}</Button>
                            <Button disabled={loading} variant="secondary" onClick={handleClose}>Batal</Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </div>
        )
    }
}