import React from 'react';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import pathImage from 'assets/images/path.png';
import dummyImage from 'assets/images/dummy.png';

import './ListCard.css';

class ListCard extends React.Component {

    render() {
        let { item } = this.props

        if (item.image === undefined || item.image === "") {
            item.image = dummyImage
        }
        return (
            < div className="list-card" >
                <Row>
                    <Col xs={3} sm={3}>
                        <img src={item.image} className="listImage"></img>
                    </Col>
                    <Col xs={9} sm={9}>
                        <Row>
                            <Col xs={7} sm={7}>
                                <p className="listCategory">#{item.categoryNews.name.toUpperCase()}</p>
                                <h2 className="listTitle">{item.name}</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6} sm={6}>
                                <p className="listAuthor"><span className="listDate">{moment(item.createdAt).format('DD MMMM YYYY')}</span></p>
                            </Col>
                            <Col xs={6} sm={6}>
                                <Row className="justify-content-end buttonDetailContainer">
                                    <Button className="buttonDetail" onClick={() => this.props.handleEdit(item)}>Ubah</Button>
                                    <Button className="buttonDetail" onClick={() => this.props.handleShowModalDelete(item)}>Hapus</Button>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div >
        )

    }
}

export default ListCard;
