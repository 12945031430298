import React from 'react';
import _ from 'lodash';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import { AiFillMinusSquare, AiFillPlusSquare } from 'react-icons/ai';


class Form1 extends React.Component {
    render() {
        const { modalData, touched, handleInputChange, handleNext, handlePrev, handleInputArrayChange, handleAddArrayChange, handleDeleteArrayChange, typeModal, handleInputCheckboxChange, handleInputCheckboxLabelChange } = this.props
        return (
            <div>
                <h1>Blok I : Identitas Umum Objek Seni Film</h1>
                <Form.Group>
                    <Form.Label className="label">Nama Karya Film</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Nama Karya Film" value={modalData.seniFilm} isInvalid={touched.seniFilm && !modalData.seniFilm} onChange={e => handleInputChange(e, 'seniFilm')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Pencipta (jika ada)</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Pencipta" value={modalData.pencipta} onChange={e => handleInputChange(e, 'pencipta')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Jumlah pemutaran dalam setahun terakhir</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Jumlah pemutaran dalam setahun terakhir" value={modalData.jumlahPertunjukan} isInvalid={touched.jumlahPertunjukan && !modalData.jumlahPertunjukan} onChange={e => handleInputChange(e, 'jumlahPertunjukan')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label"><strong>Jika tidak dapat menyebutkan jumlah pemutaran</strong><br/>Frekuensi Pemutaran Saat Ini</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} value={modalData.frekuensi} as="select" onChange={e => handleInputChange(e, 'frekuensi')}>
                        <option value="sudah_tidak_dilaksanakan">Sudah tidak dilaksanakan</option>
                        <option value="jarang">Jarang</option>
                        <option value="sering">Sering</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Jumlah orang yang mengakses film tersebut <sup>a)</sup></Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Jumlah orang yang mengakses film tersebut" value={modalData.jumlahPelaku} isInvalid={touched.jumlahPelaku && !modalData.jumlahPelaku} onChange={e => handleInputChange(e, 'jumlahPelaku')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <p className="description">Catatan: </p>
                <ol type="a">
                    <li className="description">Dapat diisi dengan angka perkiraan (estimasi)</li>
                </ol>
                <Button onClick={handleNext}>Next</Button>
            </div >
        )
    }
}

export default Form1;
