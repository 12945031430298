import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import './Choice.css';

class Choice extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { changeChoose, choose } = this.props;

    return (
      <div className="choice">
        <Row>
          <div className='wisata' onClick={() => { changeChoose('wisata') }}><h4 className={`${choose == 'wisata' ? 'active' : ''}`}><strong>10 OBJEK PEMAJUAN KEBUDAYAAN</strong></h4></div>
          <div className='budaya' onClick={() => { changeChoose('budaya') }}><h4 className={`${choose == 'budaya' ? 'active' : ''}`}><strong>CAGAR BUDAYA</strong></h4></div>
          {/* <div className='heritage' onClick={() => { changeChoose('heritage') }}><h4 className={`${choose == 'heritage' ? 'active' : ''}`}><strong>HERITAGE</strong></h4></div> */}
          <div className='museum' onClick={() => { changeChoose('museum') }}><h4 className={`${choose == 'museum' ? 'active' : ''}`}><strong>MUSEUM</strong></h4></div>
          <div className='kesenian' onClick={() => { changeChoose('kesenian') }}><h4 className={`${choose == 'kesenian' ? 'active' : ''}`}><strong>SANGGAR KESENIAN</strong></h4></div>
        </Row>
        <Row style={{marginTop: "8px"}}>
          <div className='wisata'><div className={`${choose == 'wisata' ? 'pointerActive' : 'pointer'}`}></div></div>
          <div className='budaya'><div className={`${choose == 'budaya' ? 'pointerActive' : 'pointer'}`}></div></div>
          {/* <div className='heritage'><div className={`${choose == 'heritage' ? 'pointerActive' : 'pointer'}`}></div></div> */}
          <div className='museum'><div className={`${choose == 'museum' ? 'pointerActive' : 'pointer'}`}></div></div>
          <div className='kesenian'><div className={`${choose == 'kesenian' ? 'pointerActive' : 'pointer'}`}></div></div>
        </Row>
      </div>
    );
  }
}

export default Choice;
