import React from 'react';
import AdatIstiadat from './AdatIstiadat';

import Bahasa from './Bahasa'
import Manuskrip from './Manuskrip'
import PengetahuanTradisional from './PengetahuanTradisional';
import TeknologiTradisional from './TeknologiTradisional';
import Ritus from './Ritus';
import TradisiLisan from './TradisiLisan';
import SeniUmum from './SeniUmum';
import PermainanRakyat from './PermainanRakyat';
import OlahragaTradisional from './OlahragaTradisional';
import CagarBudaya from './CagarBudaya';
import SeniTari from './SeniTari';
import SeniTeater from './SeniTeater';
import SeniSastra from './SeniSastra';
import SeniMusik from './SeniMusik';
import SeniFilm from './SeniFilm';
import SeniRupa from './SeniRupa';
import SeniMedia from './SeniMedia';

class BorangModal extends React.Component {
    state = {
        type: "bahasa",
    }

    componentDidMount() {
        // fetch sesuai type
        let { type } = this.state
        type = this.props.match.params.type
        this.setState({ type })
    }

    render() {
        let { type } = this.state;
        let borangComponent = null
        if (type === 'bahasa') {
            borangComponent = <Bahasa />
        }
        if (type === 'manuskrip') {
            borangComponent = <Manuskrip />
        }
        if (type === 'adat_istiadat') {
            borangComponent = <AdatIstiadat />
        }
        if (type === 'ritus') {
            borangComponent = <Ritus />
        }
        if (type === 'tradisi_lisan') {
            borangComponent = <TradisiLisan />
        }
        if (type === 'pengetahuan_tradisional') {
            borangComponent = <PengetahuanTradisional />
        }
        if (type === 'teknologi_tradisional') {
            borangComponent = <TeknologiTradisional />
        }
        if (type === 'seni_umum') {
            borangComponent = <SeniUmum />
        }
        if (type === 'seni_tari') {
            borangComponent = <SeniTari />
        }
        if (type === 'seni_teater') {
            borangComponent = <SeniTeater />
        }
        if (type === 'seni_sastra') {
            borangComponent = <SeniSastra />
        }
        if (type === 'seni_musik') {
            borangComponent = <SeniMusik />
        }
        if (type === 'seni_film') {
            borangComponent = <SeniFilm />
        }
        if (type === 'seni_rupa') {
            borangComponent = <SeniRupa />
        }
        if (type === 'seni_media') {
            borangComponent = <SeniMedia />
        }
        if (type === 'permainan_rakyat') {
            borangComponent = <PermainanRakyat />
        }
        if (type === 'olahraga_tradisional') {
            borangComponent = <OlahragaTradisional />
        }
        if (type === 'cagar_budaya') {
            borangComponent = <CagarBudaya />
        }
        return (
            <div>
                {borangComponent}
            </div>
        );
    }
}

export default BorangModal;
