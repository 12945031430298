import React from 'react';
import _ from 'lodash';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import { AiFillMinusSquare, AiFillPlusSquare } from 'react-icons/ai';


class Form1 extends React.Component {
    render() {
        const { modalData, touched, handleInputChange, handleNext, handlePrev, handleInputArrayChange, handleAddArrayChange, handleDeleteArrayChange, typeModal, handleInputCheckboxChange, handleInputCheckboxLabelChange } = this.props
        return (
            <div>
                <h1>Blok I : Identitas Umum Objek Seni Sastra</h1>
                <Form.Group>
                    <Form.Label className="label">Nama Karya Sastra</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Nama Karya Sastra" value={modalData.seniSastra} isInvalid={touched.seniSastra && !modalData.seniSastra} onChange={e => handleInputChange(e, 'seniSastra')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Pencipta (jika ada)</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Pencipta" value={modalData.pencipta} onChange={e => handleInputChange(e, 'pencipta')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Media penyebaran karya sastra </Form.Label>
                    {_.map(modalData.media, (item, index) => {
                        return (
                            <Form.Check
                                type="checkbox"
                            >
                                <Form.Check.Input disabled={typeModal === 'edit'} onChange={e => handleInputCheckboxChange(e, index, "media")} checked={item.checked} />
                                <Form.Check.Label onClick={e => handleInputCheckboxLabelChange(e, index, "media", !item.checked)}>{item.name}</Form.Check.Label>
                            </Form.Check>
                        )
                    })}
                    {/* <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Nama Tradisi Lisan (Judul Cerita)" value={modalData.tradisiLisan} isInvalid={touched.tradisiLisan && !modalData.tradisiLisan} onChange={e => handleInputChange(e, 'tradisiLisan')} /> */}
                    <Form.Control disabled={typeModal === 'edit' || !modalData.media[3].checked} required type="text" placeholder="Media Lainnya" value={modalData.mediaLainnya} isInvalid={touched.mediaLainnya && modalData.media[3].checked && !modalData.mediaLainnya} onChange={e => handleInputChange(e, 'mediaLainnya')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Jumlah orang yang mengakses karya sastra tersebut <sup>a)</sup></Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Jumlah orang yang mengakses karya sastra tersebut" value={modalData.jumlahOrang} isInvalid={touched.jumlahOrang && !modalData.jumlahOrang} onChange={e => handleInputChange(e, 'jumlahOrang')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <p className="description">Catatan: </p>
                <ol type="a">
                    <li className="description">Dapat diisi dengan angka perkiraan (estimasi)</li>
                </ol>
                <Button onClick={handleNext}>Next</Button>
            </div >
        )
    }
}

export default Form1;
