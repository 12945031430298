import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import './Choice.css';

class Choice extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { changeChoose, choose } = this.props;

    return (
      <div className="choice">
        <Row className="justify-content-center">
          <div className='category' onClick={() => { changeChoose('category') }}><h4 className={`${choose == 'category' ? 'active' : ''}`}><strong>Kategori</strong></h4></div>
          <div className='berita' onClick={() => { changeChoose('berita') }}><h4 className={`${choose == 'berita' ? 'active' : ''}`}><strong>Berita</strong></h4></div>
        </Row>
        <Row style={{marginTop: "8px"}} className="justify-content-center">     
          <div className='category'><div className={`${choose == 'category' ? 'pointerActive' : 'pointer'}`}></div></div>
          <div className='berita'><div className={`${choose == 'berita' ? 'pointerActive' : 'pointer'}`}></div></div>
        </Row>
      </div>
    );
  }
}

export default Choice;
