import React from 'react'

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { BsImageFill } from 'react-icons/bs'
import { AiFillCloseCircle } from 'react-icons/ai';

import { UploadApi, ExploreApi } from '../../../../api'

import './Modal.css'

export default class ExploreModal extends React.Component {
    state = {
        previewImage: null,
        previewVisible: false,
        fileName: "",
        modalData: {
            id: 0,
            file: {},
            name: "",
            description: "",
            image: "",
            categoryCultureId: 0,
            categoryCulture: {},
        },
        validated: false,
    }

    componentDidMount() {
        let { modalData, previewImage, previewVisible } = this.state
        if (this.props.modalType === "edit") {
            const { dataModal } = this.props
            modalData.file = {}
            modalData.name = dataModal.name
            modalData.description = dataModal.description
            if (!(dataModal.image === "")) {
                modalData.image = dataModal.image
                previewImage = dataModal.image
                previewVisible = true
            }
            modalData.id = dataModal.id
        }
        modalData.categoryCultureId = this.props.categoryCultureId
        this.setState({ modalData, previewImage, previewVisible })
    }

    setValidated(data) {
        let { validated } = this.state
        validated = data
        this.setState({ validated })
    }

    uploadProgress = (event) => {
        event.preventDefault()
        const file = URL.createObjectURL(event.target.files[0])
        let { modalData } = this.state
        modalData.file = event.target.files[0]
        this.setState({
            previewImage: file,
            previewVisible: true,
            fileName: event.target.files[0].name,
            modalData,
        })
    }

    removeUploadedFile = () => {
        let { modalData, previewImage, previewVisible, fileName } = this.state
        modalData.file = {}
        previewImage = null
        previewVisible = false
        fileName = ""
        modalData.image = ""
        this.setState({
            modalData,
            previewImage,
            previewVisible,
            fileName,
        })
    }

    getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    uploadFile = () => {
        let { modalData } = this.state
        if (modalData.hasOwnProperty('file')) {
            let file = modalData.file
            const payload = new FormData()
            if (modalData.file && modalData.file.size > 0) payload.append('uploadFile', file)
            return UploadApi.upload(payload)
        }
    }

    handleInputChange = (event, field) => {
        event.preventDefault()
        let { modalData } = this.state
        const data = event.target.value
        modalData[field] = data
        this.setState({ modalData })
    }

    submit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (form.checkValidity() === true) {
            event.preventDefault()
            let { modalData } = this.state
            if (modalData.file.size > 0) {
                const upload = this.uploadFile()
                upload.then(response => {
                    modalData.image = response.data.url
                    this.submitCulture(modalData)
                }).catch(err => {
                    alert(err.message)
                })
            } else {
                this.submitCulture(modalData)
            }
        }
        this.setValidated(true);
    }

    submitCulture = (data) => {
        const { modalType, categoryCultureName } = this.props
        if (modalType === "edit") {
            ExploreApi.update(data.id, data)
                .then(response => {
                    alert("Culture berhasil diupdate!")
                    setTimeout(() => {
                        this.props.callback()
                        this.props.handleClose()
                    }, 300)
                })
                .catch(err => {
                    alert(`Terjadi kesalahan pada update ${categoryCultureName} ${err}`)
                })
        } else {
            ExploreApi.create(data)
                .then(response => {
                    alert("Culture berhasil ditambah!")
                    setTimeout(() => {
                        this.props.callback()
                        this.props.handleClose()
                    }, 300)
                })
                .catch(err => {
                    alert(`Terjadi kesalahan pada tambah ${categoryCultureName} ${err}`)
                })
        }

    }

    render() {
        const { show, handleClose, modalType, categoryCultureName } = this.props
        let { previewVisible, previewImage, fileName, modalData, validated } = this.state
        if (fileName === "") {
            fileName = "Masukkan Gambar"
        }
        return (
            <div className="explore-modal">
                <Modal show={show} onHide={handleClose} size="xl" centered>
                    <Form noValidate validated={validated} onSubmit={this.submit}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {`${modalType === "edit" ? `Ubah ${categoryCultureName}` : `Tambah ${categoryCultureName}`}`}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col xs={7} lg={7} className="container-left">
                                    <Form.File id="custom-file" label={fileName} custom onChange={this.uploadProgress} />
                                    {!previewVisible && <div className="container-image">
                                        <BsImageFill size={70} color="#f29e20" style={{ margin: "auto auto" }} />
                                    </div>}
                                    {previewVisible && <div className="container-previewImage">
                                        <AiFillCloseCircle color="#f29e20" onClick={this.removeUploadedFile} />
                                        <img src={previewImage} className="image-preview" />
                                    </div>}
                                </Col>
                                <Col xs={5} lg={5}>
                                    <Form.Group>
                                        <Form.Label>Nama {categoryCultureName}</Form.Label>
                                        <Form.Control required type="text" placeholder={`Nama ${categoryCultureName}`} value={modalData.name} onChange={e => this.handleInputChange(e, 'name')} />
                                        <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Deskripsi</Form.Label>
                                        <Form.Control as="textarea" rows="8" placeholder="Deskripsi" onChange={e => this.handleInputChange(e, 'description')} value={modalData.description} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" type="submit">{`${modalType === "edit" ? `Ubah` : `Tambah`}`}</Button>
                            <Button variant="secondary" onClick={handleClose}>Batal</Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </div>
        )
    }
}