import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import './SeniMusik.css';
import NavigationHome from 'components/NavigationHome';
import Description from './components/Description';
import Form1 from './components/Form1';
import Form2 from './components/Form2';
import Form3 from './components/Form3';
import Form4 from './components/Form4';

import { BorangApi } from '../../../api'

class SeniMusik extends React.Component {
    state = {
        payload: {
            type: 'seni_musik',
            metadata: {
                seniMusik: "",
                pencipta: "",
                jumlahPertunjukan: "",
                frekuensi: "sudah_tidak_dilaksanakan",
                jumlahPelaku: "",
                jumlahMasyarakat: "",
                lembaga: "",
                sarprasMasyarakat: "",
                sarprasPemerintah: "",
                adakahPeraturan: "true",
                peraturanList: [],
                masalahList: [],
                alasanTidakDituturkan: "",
                catatanKhusus: "",
            },
        },
        touched: {
            seniMusik: false,
            pencipta: false,
            jumlahPertunjukan: false,
            frekuensi: false,
            jumlahPelaku: false,
            jumlahMasyarakat: false,
            lembaga: false,
            sarprasMasyarakat: false,
            sarprasPemerintah: false,
            adakahPeraturan: false,
            peraturanList: false,
            masalahList: false,
            alasanTidakDituturkan: false,
            catatanKhusus: false,
        },
        validated: false,
        page: 1,
        typeModal: 'add'
    }

    componentDidMount() {
        const { modalData, typeModal } = this.props
        if (typeModal === 'edit') {
            this.setState({ payload: modalData, typeModal })
        }
    }

    validateForm1 = () => {
        const metadata = this.state.payload.metadata
        let touched = this.state.touched
        touched.seniMusik = true
        touched.jumlahPertunjukan = true
        touched.frekuensi = true
        touched.jumlahPelaku = true
        touched.jumlahMasyarakat = true
        this.setState({ touched })
        if (
            metadata.seniMusik === ""
            || metadata.jumlahPertunjukan === ""
            || metadata.frekuensi === ""
            || metadata.jumlahPelaku === ""
            || metadata.jumlahMasyarakat === ""
        ) {
            // else {
            //     alert('Masih ada kolom yang belum diisi')
            // }
            return false
        } else {
            return true
        }
    }

    validateForm2 = () => {
        const metadata = this.state.payload.metadata
        let touched = this.state.touched
        touched.lembaga = true
        touched.sarprasMasyarakat = true
        touched.sarprasPemerintah = true
        this.setState({ touched })
        if (metadata.lembaga === "" || metadata.sarprasMasyarakat === "" || metadata.sarprasPemerintah === "") {
            // alert('Masih ada kolom yang belum diisi')
            return false;
        } else {
            return true;
        }
    }

    validateForm3 = () => {
        const metadata = this.state.payload.metadata
        if (metadata.adakahPeraturan === "true" && metadata.peraturanList.length === 0) {
            alert('Belum ada peraturan yang ditambahkan')
            return false;
        } else if (metadata.adakahPeraturan === 'true' && metadata.peraturanList.length > 0) {
            let benar = true
            metadata.peraturanList.forEach(peraturan => {
                if (peraturan.namaPeraturan === "" || peraturan.bentukPeraturan === "") {
                    alert('Masih ada kolom yang belum diisi')
                    benar = false
                }
            })
            if (benar) {
                return true
            }
            return false;
        } else {
            return true;
        }
    }

    validateForm4 = () => {
        const metadata = this.state.payload.metadata
        let benar = true
        if (metadata.masalahList.length > 0) {
            metadata.masalahList.forEach(masalah => {
                if (masalah.masalah === "" || masalah.upayaYangDilakukan === "" || masalah.kendalaYangDihadapi === "") {
                    alert('Masih ada kolom yang belum diisi')
                    benar = false
                }
            })

        }
        if (benar) {
            return true
        }
        return false
    }

    handleNext = () => {
        let { page, payload } = this.state
        if (page === 1) {
            const validated = this.validateForm1();
            if (!validated) {
                return
            }
            page = 2
        } else if (page === 2) {
            const validated = this.validateForm2();
            if (!validated) {
                return
            }
            page = 3
        } else if (page === 3) {
            const validated = this.validateForm3();
            if (!validated) {
                return
            }
            page = 4
        }

        this.setState({ page })
    }

    handlePrev = () => {
        let { page, payload } = this.state
        if (page === 2) {
            page = 1
        } else if (page === 3) {
            page = 2
        } else if (page === 4) {
            page = 3
        }
        this.setState({ page })
    }

    submit = (event) => {
        let validated = true
        let { page, payload, typeModal } = this.state
        if (typeModal === 'edit') {
            window.open('/dashboard/borang?type=seni_musik', '_self')
            return
        }
        if (page === 4) {
            validated = this.validateForm4()
        } else if (page === 5) {

        }
        if (!validated) {
            return
        }

        BorangApi.create(payload)
            .then(response => {
                alert("Borang berhasil dibuat!")
                setTimeout(() => {
                    window.open('/dashboard/borang?type=seni_musik', '_self')
                }, 300)
            })
            .catch(err => {
                alert(`Terjadi kesalahan pada tambah borang ${err}`)
            })
    }

    addRowInput = (event, type) => {
        let payload = this.state.payload
        if (type == 'dialek') {
            if (payload.metadata.dialekList.length > 0) {
                if (payload.metadata.dialekList[payload.metadata.dialekList.length - 1]['namaDialek'] === ""
                    || payload.metadata.dialekList[payload.metadata.dialekList.length - 1]['jumlahPenutur'] === ""
                    || payload.metadata.dialekList[payload.metadata.dialekList.length - 1]['jumlahKelompokEtnis'] === "") {
                    alert('Kolom harus diisi sebelum bisa menambahkan baris baru')
                    return
                }
            }

            payload.metadata.dialekList.push({
                namaDialek: "",
                jumlahPenutur: "",
                jumlahKelompokEtnis: "",
            })
        } else if (type == 'masalah') {
            if (payload.metadata.masalahList.length > 0) {
                if (payload.metadata.masalahList[payload.metadata.masalahList.length - 1]['masalah'] === ""
                    || payload.metadata.masalahList[payload.metadata.masalahList.length - 1]['upayaYangDilakukan'] === ""
                    || payload.metadata.masalahList[payload.metadata.masalahList.length - 1]['kendalaYangDihadapi'] === "") {
                    alert('Kolom harus diisi sebelum bisa menambahkan baris baru')
                    return
                }
            }

            payload.metadata.masalahList.push({
                masalah: "",
                upayaYangDilakukan: "",
                kendalaYangDihadapi: "",
            })
        } else if (type == 'peraturan') {
            if (payload.metadata.peraturanList.length > 0) {
                if (payload.metadata.peraturanList[payload.metadata.peraturanList.length - 1]['namaPeraturan'] === ""
                    || payload.metadata.peraturanList[payload.metadata.peraturanList.length - 1]['bentukPeraturan'] === "") {
                    alert('Kolom harus diisi sebelum bisa menambahkan baris baru')
                    return
                }
            }

            payload.metadata.peraturanList.push({
                namaPeraturan: "",
                bentukPeraturan: "",
            })
        }

        this.setState({ payload })
    }

    removeRowInput = (event, type, itemIndex) => {
        const { payload } = this.state
        if (type === 'dialek') {
            let dialekList = payload.metadata.dialekList
            dialekList.splice(itemIndex, 1)
            payload.metadata.dialekList = dialekList
        }
        else if (type === 'peraturan') {
            let peraturanList = payload.metadata.peraturanList
            peraturanList.splice(itemIndex, 1)
            payload.metadata.peraturanList = peraturanList
        }
        else if (type === 'masalah') {
            let masalahList = payload.metadata.masalahList
            masalahList.splice(itemIndex, 1)
            payload.metadata.masalahList = masalahList
        }
        this.setState({ payload })
    }

    handleInputChange = (event, field) => {
        event.preventDefault()
        let { payload, touched } = this.state
        let data = event.target.value
        payload.metadata[field] = data
        touched[field] = true
        this.setState({ payload, touched })
    }

    handleInputCheckboxChange = (event, index, field) => {
        let { payload, touched } = this.state
        payload.metadata[field][index]['checked'] = event.target.checked
        if (payload.metadata[field][index]['value'] === 'lainnya' && !event.target.checked) {
            if (field === "jenisSeniMusik") {
                payload.metadata.jenisLainnya = ""
                touched.jenisLainnya = false
            }
            if (field === "mediumPenyaji") {
                payload.metadata.mediumLainnya = ""
                touched.mediumLainnya = false
            }
        }
        this.setState({ payload, touched })
    }

    handleInputCheckboxLabelChange = (event, index, field, value) => {
        let { payload, touched, typeModal } = this.state
        if (typeModal !== 'edit') {
            payload.metadata[field][index]['checked'] = value
            if (payload.metadata[field][index]['value'] === 'lainnya' && !value) {
                if (field === "jenisSeniMusik") {
                    payload.metadata.jenisLainnya = ""
                    touched.jenisLainnya = false
                }
                if (field === "mediumPenyaji") {
                    payload.metadata.mediumLainnya = ""
                    touched.mediumLainnya = false
                }

            }
            this.setState({ payload, touched })
        }
    }

    changeInput = async (e, type, form, index) => {
        e.preventDefault()
        let { payload } = this.state
        if (type === 'dialek') { // product item
            payload.metadata.dialekList[index][form] = e.target.value
        } else if (type === 'masalah') {
            payload.metadata.masalahList[index][form] = e.target.value
        } else if (type === 'peraturan') {
            payload.metadata.peraturanList[index][form] = e.target.value
        }

        this.setState({ payload })
    }

    render() {
        const { validated, payload, touched, page, typeModal } = this.state
        let form = <Form1
            handleNext={this.handleNext}
            modalData={payload.metadata}
            handleAddArrayChange={this.addRowInput}
            handleDeleteArrayChange={this.removeRowInput}
            handleInputChange={this.handleInputChange}
            handleInputArrayChange={this.changeInput}
            handleInputCheckboxChange={this.handleInputCheckboxChange}
            handleInputCheckboxLabelChange={this.handleInputCheckboxLabelChange}
            touched={touched}
            typeModal={typeModal}
        />
        if (page === 2) {
            form = <Form2
                handlePrev={this.handlePrev}
                handleNext={this.handleNext}
                modalData={payload.metadata}
                handleAddArrayChange={this.addRowInput}
                handleDeleteArrayChange={this.removeRowInput}
                handleInputChange={this.handleInputChange}
                handleInputArrayChange={this.changeInput}
                touched={touched}
                typeModal={typeModal}
            />
        }
        if (page === 3) {
            form = <Form3
                handlePrev={this.handlePrev}
                handleNext={this.handleNext}
                modalData={payload.metadata}
                handleAddArrayChange={this.addRowInput}
                handleDeleteArrayChange={this.removeRowInput}
                handleInputChange={this.handleInputChange}
                handleInputArrayChange={this.changeInput}
                touched={touched}
                typeModal={typeModal}
            />
        }
        if (page === 4) {
            form = <Form4
                handlePrev={this.handlePrev}
                handleNext={this.handleNext}
                modalData={payload.metadata}
                handleAddArrayChange={this.addRowInput}
                handleDeleteArrayChange={this.removeRowInput}
                handleInputChange={this.handleInputChange}
                handleInputArrayChange={this.changeInput}
                touched={touched}
                handleSubmit={this.submit}
                typeModal={typeModal}
            />
        }
        return (
            <div className="seniMusik">
                <NavigationHome />
                <Container style={{ paddingTop: "100px" }}>
                    <Row>
                        <Col sm="12">
                            <Description />
                        </Col>
                        <Col sm="12" className="form-box">
                            <Form noValidate validated={validated} onSubmit={this.submit}>
                                {form}
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default SeniMusik;
