import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import dummyImage from 'assets/images/dummy.png';
import './Inspiration.css';

class Inspiration extends React.Component {
  render() {
    return (
      <div className="inspiration">
        <Container>
          <Row className="justify-content-between inspiration-top">
            <Col className="title-inspiration">
              <h2>Temukan Inspirasimu</h2>
              <h4>Jelajahi video dan foto yang bisa memberikanmu referensi berwisata keliling makassar</h4>
            </Col>
            <Col className="all-videos">
              <h4>Lihat semua video ></h4>
            </Col>
          </Row>
          <Row className="inspiration-choice">
            <Col sm="2"><h4>Video</h4></Col>
            <Col sm="2"><h4>Gambar</h4></Col>
          </Row>
          <Row className="inspiration-content">
            <Col sm="7" className="main-content">
              <img src={dummyImage} />
              <h4>Surga Wisata - Kuliner, Sejara sampai Pemandangan Apik</h4>
              <h6>18.336 views - <strong>Apr 14, 2018</strong></h6>
            </Col>
            <Col sm="5" className="choice-content">
              <Row className="choice align-items-center">
                <Col sm="5">
                  <img src={dummyImage} />
                </Col>
                <Col sm="7" className="text">
                  <h6>Surga Wisata - Kuliner, Sejara sampai Pemandangan Apik</h6>
                  <p>Apr 14, 2018</p>
                </Col>
              </Row>
              <hr />
              <Row className="choice align-items-center">
                <Col sm="5">
                  <img src={dummyImage} />
                </Col>
                <Col sm="7" className="text">
                  <h6>Surga Wisata - Kuliner, Sejara sampai Pemandangan Apik</h6>
                  <p>Apr 14, 2018</p>
                </Col>
              </Row>
              <hr />
              <Row className="choice align-items-center">
                <Col sm="5">
                  <img src={dummyImage} />
                </Col>
                <Col sm="7" className="text">
                  <h6>Surga Wisata - Kuliner, Sejara sampai Pemandangan Apik</h6>
                  <p>Apr 14, 2018</p>
                </Col>
              </Row>
              <hr />
              <Row className="choice align-items-center">
                <Col sm="5">
                  <img src={dummyImage} />
                </Col>
                <Col sm="7" className="text">
                  <h6>Surga Wisata - Kuliner, Sejara sampai Pemandangan Apik</h6>
                  <p>Apr 14, 2018</p>
                </Col>
              </Row>
              <hr />
              <Row className="choice align-items-center">
                <Col sm="5">
                  <img src={dummyImage} />
                </Col>
                <Col sm="7" className="text">
                  <h6>Surga Wisata - Kuliner, Sejara sampai Pemandangan Apik</h6>
                  <p>Apr 14, 2018</p>
                </Col>
              </Row>
              <hr />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Inspiration;
