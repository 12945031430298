import React from 'react';
import {
	BrowserRouter as Router,
	Route,
	Switch
} from 'react-router-dom'

import UserGlobalState from "../src/context/user-context/UserGlobalState"
import AppRoute from "../src/router"


class Main extends React.Component {
	render() {
		return (
			<UserGlobalState>
				<AppRoute />
			</UserGlobalState>
		);
	}
}

export default Main;
