import React from 'react';
import Container from 'react-bootstrap/Container';

import './Home.css';
import NavigationHome from 'components/NavigationHome';
import Footer from 'components/Footer';
import Banner from './components/Banner';
import Explore from './components/Explore';
import Highlights from './components/Highlights';
import Inspiration from './components/Inspiration';
import Contact from './components/Contact';

class Home extends React.Component {
  render() {
    return(
      <div className='home'>
        <NavigationHome />
        <Banner />
        <Explore />
        <Highlights />
        <Inspiration />
        <Container>
          <hr />
        </Container>
        <Contact />
        <Footer />
      </div>
    );
  }
}

export default Home;
