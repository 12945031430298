import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import './Choice.css';

class Choice extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { changeChoose, choose } = this.props;

    return (
      <div className="choice">
        <Row className="justify-content-center">
          <div className='image' onClick={() => { changeChoose('image') }}><h4 className={`${choose == 'image' ? 'active' : ''}`}><strong>IMAGE</strong></h4></div>
          <div className='video' onClick={() => { changeChoose('video') }}><h4 className={`${choose == 'video' ? 'active' : ''}`}><strong>VIDEO</strong></h4></div>
        </Row>
        <Row style={{marginTop: "8px"}} className="justify-content-center">     
          <div className='image'><div className={`${choose == 'image' ? 'pointerActive' : 'pointer'}`}></div></div>
          <div className='video'><div className={`${choose == 'video' ? 'pointerActive' : 'pointer'}`}></div></div>
        </Row>
      </div>
    );
  }
}

export default Choice;
