import React from 'react';
import _ from 'lodash';
import moment from 'moment'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Pagination from "react-js-pagination";
import Button from 'react-bootstrap/Button';
import { AiFillPlusSquare } from 'react-icons/ai'

import Navigation from 'components/NavigationHome';
import Footer from 'components/Footer';
import { dateToLocal } from '../../utils/formatter/datetime'

import './BorangForm.css';

import { BorangApi } from '../../api'

var idLocale = require('moment/locale/id');
moment.locale('id', idLocale);
class BorangForm extends React.Component {
    state = {
        borangForms: [],
        type: "bahasa",
        page: 0,
        count: 0,
    }

    componentWillMount() {
        const query = new URLSearchParams(this.props.location.search);
        let type = "bahasa"
        let page = 1
        for (let param of query.entries()) {
            switch (param[0]) {
                case 'type':
                    type = param[1];
                    break;
                case 'page':
                    page = param[1];
                    break;
                default:
                    break;
            }
        }


        this.setState({ type, page })
    }

    componentDidMount() {
        // fetch sesuai type
        const { type, page } = this.state
        this.fetchBorangs(type, page)
    }


    fetchBorangs = (type, page) => {
        return new Promise((resolve, reject) => {
            BorangApi.get({ limit: 20, page: page, type: type })
                .then(async ({ data }) => {
                    let borangForms = data.data
                    let count = data.count
                    await this.setState({ borangForms, count }, () => {
                        resolve()
                    })
                })
                .catch(err => {
                    alert(`Terjadi kesalahan saat mengambil data borang ${err}`)
                    reject()
                })

        })
    }

    changeChoose(e) {
        window.open('/dashboard/borang?type=' + e.target.value, '_self')
    }

    handlePageChange(pageNumber) {
        const queryParams = [];
        const { type } = this.state
        queryParams.push('type=' + type);
        queryParams.push('page=' + pageNumber)
        const queryString = queryParams.join('&');
        window.open('/dashboard/borang?' + queryString, '_self')
    }

    handleAddBorang = () => {
        const {type} = this.state
        window.open('/dashboard/borang/' + type + '/add', '_self' )
    }

    handleEditModal = (item) => {
        window.open('/dashboard/borang/detail/' + item.id, '_self')
    }

    render() {
        let { type, borangForms, count, page } = this.state;
        return (
            <div className="explore-list">
                <Navigation />
                <Container className="content">
                    <Row style={{ marginBottom: "10px" }}>
                        <Col xs={6} sm={6}>
                            <h1 className="title">Borang</h1>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "10px" }}>
                        <Col xs={3} sm={3}>
                            <Form.Control value={type} as="select" onChange={e => this.changeChoose(e)}>
                                <option value="bahasa">1. Bahasa</option>
                                <option value="manuskrip">2. Manuskrip</option>
                                <option value="adat_istiadat">3. Adat Istiadat</option>
                                <option value="ritus">4. Ritus</option>
                                <option value="tradisi_lisan">5. Tradisi Lisan</option>
                                <option value="pengetahuan_tradisional">6. Pengetahuan Tradisional</option>
                                <option value="teknologi_tradisional">7. Teknologi Tradisional</option>
                                <option value="seni_umum">8. Seni (Umum)</option>
                                <option value="seni_tari">8a. Seni Tari</option>
                                <option value="seni_teater">8b. Seni Teater</option>
                                <option value="seni_sastra">8c. Seni Sastra</option>
                                <option value="seni_musik">8d. Seni Musik</option>
                                <option value="seni_film">8e. Seni Film</option>
                                <option value="seni_rupa">8f. Seni Rupa</option>
                                <option value="seni_media">8g. Seni Media</option>
                                <option value="permainan_rakyat">9. Permainan Rakyat</option>
                                <option value="olahraga_tradisional">10. Olahraga Tradisional</option>
                                <option value="cagar_budaya">11. Cagar Budaya</option>
                            </Form.Control>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "30px" }}>
                        <Col xs={8} lg={8}>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Tanggal Input</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {_.map(borangForms, item => {
                                        return (
                                            <tr>
                                                <td>{item.id}</td>
                                                <td>{dateToLocal(item.createdAt)}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                        <Col xs={4} lg={4}>
                            <Button className="buttonAdd" style={{ marginBottom: "12px", marginLeft: "15px", width: "85%" }} onClick={this.handleAddBorang}><AiFillPlusSquare size={20} color="white" style={{ marginRight: "5px" }} />Tambah Borang</Button>
                            {_.map(borangForms, item => {
                                return (
                                    // <Row>
                                        <Button onClick={() => this.handleEditModal(item)} className="buttonAdd" style={{ marginBottom: "12px", marginTop: "1px", marginRight: "50px", marginLeft: "15px", width: "85%" }}>Detail</Button>
                                    // </Row>
                                )
                            })}
                        </Col>
                    </Row>
                    {count > 0 ?
                        <Row>
                            <Col>
                                <Pagination
                                    activePage={page}
                                    itemsCountPerPage={20}
                                    totalItemsCount={count}
                                    pageRangeDisplayed={20}
                                    onChange={this.handlePageChange.bind(this)}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                />
                            </Col>
                        </Row> : null
                    }
                </Container>
                <Footer />
            </div>
        );
    }
}

export default BorangForm;
