import React from 'react'
import {
	BrowserRouter as Router,
	Route,
	Switch
} from 'react-router-dom'

import PrivateRoute from './private-router'
import withUserState from '../context/user-context/withUserState'
import { SITE_URL, MENU } from '../config'
import Home from '../scenes/Home'
import ExploreList from '../scenes/ExploreList'
import NewsList from '../scenes/NewsList'
import InspirationList from '../scenes/InspirationList'
import Login from '../scenes/Login'
import BorangForm from '../scenes/BorangForm'
import BorangModal from '../scenes/BorangForm/modal'
import BorangDetail from '../scenes/BorangForm/detail'
import LandingPageHome from '../scenes/LandingPage/scenes/Home';
import LandingPageProfile from '../scenes/LandingPage/scenes/Profile';
import LandingPageNews from '../scenes/LandingPage/scenes/News';
import LandingPageNewsList from '../scenes/LandingPage/scenes/NewsList';
import LandingPageVideoList from '../scenes/LandingPage/scenes/VideoList';
import LandingPageExploreList from '../scenes/LandingPage/scenes/ExploreList';
import LandingPageExplore from '../scenes/LandingPage/scenes/Explore';

const AppRoute = props => (
	<Router>
		<Switch>
			<Route exact path={MENU.DASHBOARD_LOGIN} component={Login} />
			<Route exact path={`/explores`} component={LandingPageExploreList} />
			<Route exact path={'/explores/detail/:id'} component={LandingPageExplore} />
			<Route exact path={`/inspirations`} component={LandingPageVideoList} />
			<Route exact path={`/news/detail/:id`} component={LandingPageNews} />
			<Route exact path={'/news'} component={LandingPageNewsList} />
			<Route exact path={'/profile'} component={LandingPageProfile} />
			<Route exact path={`/`} component={LandingPageHome} />
			<PrivateRoute exact path={`${MENU.DASHBOARD_NEWS}`} component={NewsList} />
			<PrivateRoute exact path={`${MENU.DASHBOARD_EXPLORE}`} component={ExploreList} />
			<PrivateRoute exact path={`${MENU.DASHBOARD_INSPIRATION}`} component={InspirationList} />
			<PrivateRoute exact path={`${MENU.DASHBOARD_BORANG}/detail/:id`} component={BorangDetail} />
			<PrivateRoute exact path={`${MENU.DASHBOARD_BORANG}/:type/add`} component={BorangModal} />
			<PrivateRoute exact path={`${MENU.DASHBOARD_BORANG}`} component={BorangForm} />
			<PrivateRoute exact path={`${MENU.DASHBOARD_HOME}`} component={ExploreList} />
			{/* <Route component={Page404} /> */}
		</Switch>
	</Router>
)

export default withUserState(AppRoute)