import Cookies from 'universal-cookie'

export var API_URL = 'http://103.214.112.225:8082/'
// export var API_URL = 'http://localhost:8082/'


export const SITE_COOKIES = {
	SESSIONID: 'sessionId',
	TOKEN: '_token',
	DEBUG: '_debug',
	EMAIL: '_email',
	NAME: '_name'
}

export const COOKIES = new Cookies()
export const SITE_URL = `/`
export const MENU = {
	DASHBOARD_HOME: `${SITE_URL}dashboard`,
	DASHBOARD_LOGIN: `${SITE_URL}auth/login`,
	DASHBOARD_EXPLORE: `${SITE_URL}dashboard/explores`,
	DASHBOARD_NEWS: `${SITE_URL}dashboard/news`,
	DASHBOARD_INSPIRATION: `${SITE_URL}dashboard/inspirations`,
	DASHBOARD_BORANG: `${SITE_URL}dashboard/borang`,
}
