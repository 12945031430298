import React from 'react'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'


import './ModalDelete.css'

export default class ModalDelete extends React.Component {
    render() {
        const { show, handleClose, dataModal, handleDelete } = this.props
        return (
            <Modal show={show} onHide={handleClose} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Apakah Anda yakin ingin menghapus {dataModal.name}?</h5>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleDelete(dataModal.id)}>Ya</Button>
                    <Button variant="secondary" onClick={handleClose}>Tidak</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}