import React from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import visi1Image from '../../../../assets/images/visi-1.png';
import visi2Image from '../../../../assets/images/visi-2.png';
import misi1Image from '../../../../assets/images/misi-1.png';
import geografisImage from '../../../../assets/images/geografis.png';
import aboutUsImage from '../../../../assets/images/aboutus.png';
import structureImage from '../../../../assets/images/structure-image.png';
import strukturImage from '../../../../assets/images/struktur.png'
import './Content.css';

class Content extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { choose } = this.props;

    return (
      <div className="content">
        <Container>
          {choose == 'about' && (
            <Row>
              <Col sm="6">
                <img src={aboutUsImage} />
              </Col>
              <Col sm="6" className="text">
                <h1 style={{textAlign: "left"}}>Mengenal Dinas Kebudayaan Kota Makassar</h1>
                <p>Dinas Kebudayaan Kota Makassar merupakan salah satu Perangkat Daerah yang menyelenggarakan urusan pemerintahan dalam bidang kebudayaan. Kedudukan dinas tersebut merupakan unsur pelaksana urusan pemerintahan yang menyelenggarakan bidang kebudayaan yang diatur dalam Peraturan Daerah Kota Makassar Nomor 08 Tahun 2016 tentang Pembentukan dan Susunan Perangkat Daerah Kota Makassar.</p>
                <p>Perangkat Daerah Dinas Kebudayaan Kota Makassar mempunyai tugas pokok yaitu membantu Walikota melaksanakan urusan pemerintahan bidang kebudayaan yang menjadi kewenangan daerah dan tugas pembantuan yang ditugaskan kepada daerah. Untuk melaksanakan tugas pokok sebagaimana dimaksud, Dinas Kebudayaan mempunyai fungsi sebagai berikut:</p>
                <ol>
                  <li>Perumusan kebijakan penyelenggaraan Urusan Pemerintahan bidang kebudayaan;</li>
                  <li>Pelaksanaan kebijakan Urusan Pemerintahan bidang kebudayaan;</li>
                  <li>Pelaksanaan evaluasi dan pelaporan Urusan Pemerintahan bidang kebudayaan;</li>
                  <li>Pelaksanaan administrasi dinas Urusan Pemerintahan bidang kebudayaan;</li>
                </ol>
              </Col>
            </Row>
          )}
          {choose == 'visi' && (

            <Row>
              <Col sm="6">
                <Card>
                  <Card.Img variant="top" className="visi1-image" src={visi1Image}/>
                  <Card.Body>
                    <h1 className="card-title">Visi Kami</h1>
                    <p className="card-content">Terwujudnya Kota Makassar yang Berbudaya</p>
                  </Card.Body>
                </Card>
                <img className="visi2-image" src={visi2Image} />
              </Col>
              <Col sm="6">
                <Card>
                  <Card.Img variant="top" className="misi1-image" src={misi1Image}/>
                  <Card.Body>
                    <h1 className="card-title">Misi Kami</h1>
                    <ol className="card-content-ol">
                      <li className="card-content">Peningkatan kapasitas kelembagaan Dinas Kebudayaan Kota Makassar</li>
                      <li className="card-content">Mewujudkan pelestarian nilai-nilai sejarah tradisi dan cagar budaya</li>
                      <li className="card-content">Mewujudkan sarana dan prasarana yang handal dan mendukung pembangunan dan pelestarian budaya</li>
                      <li className="card-content">Mewujudkan museum kota sebagai museum representative di kenal masyarakat dunia dan menjadi salah satu pusat budaya Kota Makassar</li>
                      <li className="card-content">Mewujudkan dinas kebudayaan makassar yang dinamis yang dapat dibanggakan oleh masyarakat dan dunia melalui internalisasi dan penguatan diplomasi budaya serta meningkatkan pelestarian warisan budaya dan mempertahankan unsur budaya</li>
                    </ol>
                    {/* <p className="card-content">Peningkatan kapasitas kelembagaan Dinas Kebudayaan kota makassar</p> */}
                    {/* <p className="card-content">Mewujudkan pelestarian Nilai-nilai Sejarah Tradisi dan Cagar Budaya</p> */}
                    {/* <p className="card-content">Mewujudkan sarana dan prasarana yang handal dan mendukung pembangunan dan pelestarian budaya</p> */}
                    {/* <p className="card-content">Mewujudkan Museum kota sebagai museum representative di kenal masyarakat Dunia dan menjadi salah satu pusat Budaya Kota Makassar</p> */}
                    {/* <p className="card-content">Mewujudkan dinas kebudayaan makassar yang dinamis yang dapat dibanggakan oleh masyarakat dan dunia melalui internalisasi dan penguatan diplomasi budaya serta meningkatkan pelestarian warisan budaya dan mempertahankan unsur budaya</p> */}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
          {choose == 'structure' && (
            <Row>
              <div className="text">
                <h1>Struktur Organisasi</h1>
                <img src={strukturImage} className="structure-image"/>
              </div>
            </Row>
          )}
        </Container>
      </div>
    );
  }
}

export default Content;
