import React from 'react';
import Card from 'react-bootstrap/Card';

import './CardHighlights.css';
import shareImg from '../../assets/images/path_2@2x.png'

class CardHighlights extends React.Component {
  render() {
    let { image, type, title, date } = this.props;

    return (
      <div className="card-highlights">
        <Card>
          <Card.Img variant="top" src={image} />
          <Card.Body style={{ width: "300px", padding: "1 rem" }}>
            <Card.Text className="type">{type}</Card.Text>
            <Card.Title className="title">{title}</Card.Title>
            <Card.Text className="date">{date}</Card.Text>
            <div className="share">
              <Card.Text className="shareFont">Bagikan</Card.Text>
              <img src={shareImg} style={{width: "22px", height: "22px"}}/>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default CardHighlights;
