import React from 'react';
import _ from 'lodash';
import moment from 'moment'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import dummyImage from '../../assets/images/dummy.png';
import Navigation from '../../components/Navigation';
import Footer from '../../components/Footer';
import ArticleCard from '../../components/ArticleCard';
import './News.css';

import { NewsApi } from '../../api'

var idLocale = require('moment/locale/id');
moment.locale('id', idLocale);
class News extends React.Component {
	state = {
		news: {
			categoryNews: {},
		},
		articleCards: [],
	}

	componentDidMount() {
		this.fetchNewsDetail(this.props.match.params.id)
		this.fetchNews()
	}

	fetchNewsDetail = async newsID => {
		NewsApi.detail(newsID)
			.then(async ({ data }) => {
				let news = data
				news.createdAt = moment(news.createdAt).format('ddd, DD MMMM YYYY HH:mm')

				await this.setState({
					news,
				})
			})
			.catch(async err => {
				alert(`Terjadi kesalahan saat mengambil data highlights dengan ID ${newsID}`)
			})
	}

	fetchNews = () => {
		return new Promise((resolve, reject) => {
			NewsApi.get({ limit: 3, page: 1 })
				.then(async ({ data }) => {
					let articleCards = data.data
					await this.setState({ articleCards }, () => {
						resolve()
					})
				})
				.catch(err => {
					alert('Terjadi kesalahan saat mengambil data highlights')
					reject()
				})

		})
	}

	handleNewsDetail(id) {
		window.open('/news/detail/' + id, '_self')
	}

	render() {
		const { news, articleCards } = this.state
		return (
			<div className="news">
				<Navigation />
				<Container className="content">
					<Row>
						<Col sm="8">
							<h1 className="title">{news.name}</h1>
						</Col>
					</Row>
					<Row>
						<Col sm="8">
							<p>{news.publishedBy}, {news.createdAt}</p>
						</Col>
						<Col sm="4" className="related-article">
							<p>Artikel Terkait</p>
						</Col>
					</Row>
					<Row>
						<Col sm="8" className="main">
							<img className="headline-image" src={news.image} />
							<div style={{ whiteSpace: 'pre-line', textAlign: 'justify', lineHeight: '1.6' }}>{news.description}</div>
						</Col>
						<Col sm="4">
							{_.map(articleCards, articleCard => {
								return (
									<div onClick={() => this.handleNewsDetail(articleCard.id)} className="mouse-over">
										<ArticleCard image={articleCard.image} title={articleCard.name} author={articleCard.publishedBy} />
									</div>
								)
							})}
						</Col>
					</Row>
				</Container>
				<Footer />
			</div>
		)
	}
}

export default News;
