import React from 'react'

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { BsImageFill } from 'react-icons/bs'
import { AiFillCloseCircle } from 'react-icons/ai';

import { UploadApi, ExploreApi, CategoryNewsApi } from '../../../../api'

import './ModalCategory.css'

export default class CategoryNewsModal extends React.Component {
    state = {
        modalData: {
            id: 0,
            name: "",
        },
        validated: false,
    }

    componentDidMount() {
        let { modalData } = this.state
        if (this.props.modalType === "edit") {
            const { dataModal } = this.props
            modalData.id = dataModal.id
            modalData.name = dataModal.name
        }
        this.setState({ modalData })
    }

    setValidated(data) {
        let { validated } = this.state
        validated = data
        this.setState({ validated })
    }

    handleInputChange = (event, field) => {
        event.preventDefault()
        let { modalData } = this.state
        const data = event.target.value
        modalData[field] = data
        this.setState({ modalData })
    }

    submit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        if (form.checkValidity() === true) {
            let { modalData } = this.state
            this.submitCategoryNews(modalData)

        }

        this.setValidated(true);

    }


    submitCategoryNews = (data) => {
        const { modalType } = this.props
        if (modalType === "edit") {
            CategoryNewsApi.update(data.id, data)
                .then(response => {
                    alert("Kategori Berita berhasil diupdate!")
                    setTimeout(() => {
                        this.props.callback()
                        this.props.handleClose()
                    }, 300)
                })
                .catch(err => {
                    alert(`Terjadi kesalahan pada update kategori berita ${err}`)
                })
        } else {
            CategoryNewsApi.create(data)
                .then(response => {
                    alert("Kategori berita berhasil ditambah!")
                    setTimeout(() => {
                        this.props.callback()
                        this.props.handleClose()
                    }, 300)
                })
                .catch(err => {
                    alert(`Terjadi kesalahan pada tambah kategori berita ${err}`)
                })
        }

    }

    render() {
        const { show, handleClose, modalType, handleEdit } = this.props
        let { modalData, validated } = this.state
        return (
            <div className="categoryNews-modal">
                <Modal show={show} onHide={handleClose} size="lg" centered>
                    <Form noValidate validated={validated} onSubmit={this.submit}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {`${modalType === "edit" ? "Ubah Kategori Berita" : "Tambah Kategori Berita"}`}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col lg={12}>
                                    <Form.Group>
                                        <Form.Control required type="text" placeholder="Nama Kategori Berita" value={modalData.name} onChange={e => this.handleInputChange(e, 'name')} />
                                        <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" type="submit">{`${modalType === "edit" ? "Ubah" : "Tambah"}`}</Button>
                            <Button variant="secondary" onClick={handleClose}>Batal</Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </div>
        )
    }
}