import React from 'react';

import { NavLink, Navlink } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './NavigationHome.css';
import logo from '../../assets/images/logo.png';

class NavigationHome extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Navbar className={`navigation-home`} expand="lg">
        <Container>
          <Navbar.Brand href="/" className='brand'><img src={logo} /></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="/" className="nav-link">Beranda</Nav.Link>
              <Nav.Link href="/profile" className="nav-link">Profil</Nav.Link>
              <Nav.Link href="/news" className="nav-link">Informasi</Nav.Link>
              <Nav.Link href="/explores" className="nav-link">Wisata Budaya</Nav.Link>
              <Nav.Link className="nav-link" href="/auth/login">Login</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default NavigationHome;
