import React from 'react';
import _ from 'lodash';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import { AiFillMinusSquare, AiFillPlusSquare } from 'react-icons/ai';


class Form1 extends React.Component {
    render() {
        const { modalData, touched, handleInputChange, handleNext, handlePrev, handleInputArrayChange, handleAddArrayChange, handleDeleteArrayChange, typeModal, handleInputCheckboxChange, handleInputCheckboxLabelChange } = this.props
        return (
            <div>
                <h1>Blok I : Identitas Umum Objek Tradisi Lisan</h1>
                <Form.Group>
                    <Form.Label className="label">Nama Tradisi Lisan (Judul Cerita)</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Nama Tradisi Lisan (Judul Cerita)" value={modalData.tradisiLisan} isInvalid={touched.tradisiLisan && !modalData.tradisiLisan} onChange={e => handleInputChange(e, 'tradisiLisan')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Jenis Tradisi Lisan</Form.Label>
                    {_.map(modalData.jenisTradisiLisan, (item, index) => {
                        return (
                            <Form.Check
                                type="checkbox"
                            >
                                <Form.Check.Input disabled={typeModal === 'edit'} onChange={e => handleInputCheckboxChange(e, index, "jenisTradisiLisan")} checked={item.checked} />
                                <Form.Check.Label onClick={e => handleInputCheckboxLabelChange(e, index, "jenisTradisiLisan", !item.checked)}>{item.name}</Form.Check.Label>
                            </Form.Check>
                        )
                    })}
                    {/* <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Nama Tradisi Lisan (Judul Cerita)" value={modalData.tradisiLisan} isInvalid={touched.tradisiLisan && !modalData.tradisiLisan} onChange={e => handleInputChange(e, 'tradisiLisan')} /> */}
                    <Form.Control disabled={typeModal === 'edit' || !modalData.jenisTradisiLisan[6].checked} required type="text" placeholder="Jenis Lainnya" value={modalData.jenisLainnya} isInvalid={touched.jenisLainnya && modalData.jenisTradisiLisan[6].checked && !modalData.jenisLainnya} onChange={e => handleInputChange(e, 'jenisLainnya')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Etnis Penutur Tradisi Lisan</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Etnis Penutur Tradisi Lisan" value={modalData.etnisPenutur} isInvalid={touched.etnisPenutur && !modalData.etnisPenutur} onChange={e => handleInputChange(e, 'etnisPenutur')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Medium Penyajian</Form.Label>
                    {_.map(modalData.mediumPenyaji, (item, index) => {
                        return (
                            <Form.Check
                                disabled={typeModal === 'edit'}
                                type="checkbox"
                                label={item.name}
                            >
                                <Form.Check.Input disabled={typeModal === 'edit'} onChange={e => handleInputCheckboxChange(e, index, "mediumPenyaji")} checked={item.checked} />
                                <Form.Check.Label onClick={e => handleInputCheckboxLabelChange(e, index, "mediumPenyaji", !item.checked)}>{item.name}</Form.Check.Label>
                            </Form.Check>
                        )
                    })}
                    <Form.Control disabled={typeModal === 'edit' || !modalData.mediumPenyaji[4].checked} required type="text" placeholder="Medium Penyaji Lainnya" value={modalData.mediumLainnya} isInvalid={touched.mediumLainnya && modalData.mediumPenyaji[4].checked && !modalData.mediumLainnya} onChange={e => handleInputChange(e, 'mediumLainnya')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Frekuensi Pelaksanaan saat ini</Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} value={modalData.frekuensi} as="select" onChange={e => handleInputChange(e, 'frekuensi')}>
                        <option value="sudah_tidak_dilaksanakan">Sudah tidak dilaksanakan</option>
                        <option value="jarang">Jarang</option>
                        <option value="sering">Sering</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="label">Jumlah penutur/pencerita <sup>a)</sup></Form.Label>
                    <Form.Control disabled={typeModal === 'edit'} required type="text" placeholder="Jumlah penutur/pencerita" value={modalData.jumlahPenutur} isInvalid={touched.jumlahPenutur && !modalData.jumlahPenutur} onChange={e => handleInputChange(e, 'jumlahPenutur')} />
                    <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                </Form.Group>
                <p className="description">Catatan: </p>
                <ol type="a">
                    <li className="description">Dapat diisi dengan angka perkiraan (estimasi)</li>
                </ol>
                <Button onClick={handleNext}>Next</Button>
            </div >
        )
    }
}

export default Form1;
