import React, { Component, createContext } from 'react'

export const UserContext = createContext()

const getUser = () => {
	return JSON.parse(localStorage.getItem('userBudayaMakassar'))
}

export default class UserGlobalState extends Component {
	constructor(props) {
		super(props)
		this.state = {
			user: getUser() || {},
			setUserData: user => {
				this.setState({ user }, () => {
					localStorage.setItem('userBudayaMakassar', JSON.stringify(this.state.user))
				})
			},
			setUserDataFromLocalStorage: () => {
				const loggedInUser = getUser()
				this.state.setUserData(loggedInUser)
			}
		}
	}

	componentDidMount() {
		this.state.setUserDataFromLocalStorage()
	}

	render() {
		return (
			<UserContext.Provider value={this.state}>
				{this.props.children}
			</UserContext.Provider>
		)
	}
} 