import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import './Choice.css';

class Choice extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { changeChoose, choose } = this.props;

    return (
      <div className="choice">
        <Container>
          <Row className="justify-content-center">
            <Col sm="2" onClick={ () => { changeChoose('about') } }><h4 className={`${choose=='about'?'active':''}`}><strong>Tentang Kami</strong></h4></Col>
            <Col sm="2" onClick={ () => { changeChoose('visi') } }><h4 className={`${choose=='visi'?'active':''}`}><strong>Visi & Misi</strong></h4></Col>
            <Col sm="3" onClick={ () => { changeChoose('structure') } }><h4 className={`${choose=='structure'?'active':''}`}><strong>Struktur Organisasi</strong></h4></Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Choice;
