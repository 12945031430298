import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './Footer.css';

class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <Container>
          <Row>
            <Col sm="6" className="information">
              <h1>Dinas Kebudayaan Pemerinta Kota Makassar</h1>
              <p>Dinas Kebudayaan Kota Makassar merupakan salah satu Perangkat Daerah yang menyelenggarakan urusan pemerintahan dalam bidang kebudayaan. Kedudukan dinas tersebut merupakan unsur pelaksana urusan pemerintahan yang menyelenggarakan bidang kebudayaan yang diatur dalam Peraturan Daerah Kota Makassar Nomor 08 Tahun 2016 tentang Pembentukan dan Susunan Perangkat Daerah Kota Makassar.</p>
            </Col>
            <Col sm="6">
              <div className="suggest-box">
                <div className="block-suggest">
                  <p><strong>Punya saran?</strong></p>
                  <p>Hubungi kami kapan pun</p>
                </div>
                <div className="block-suggest">
                  <Row>
                    <Col sm="6">
                      <p className="label">Email</p>
                      <p>muhhabibih@gmail.com</p>
                    </Col>
                    <Col sm="6">
                      <p className="label">Telepon</p>
                      <p>085299724084</p>
                    </Col>
                  </Row>
                </div>
                <div className="block-suggest">
                  <p className="label">Alamat</p>
                  <p>Jl. Balaikota No. 11 Kelurahan Baru</p>
                  <p>Kecamatan Ujung Pandang</p>
                  <p>Kota Makassar</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="navigation-footer">
            <Col sm="3">
              <h4>Profil</h4>
              <p>Tentang Kami</p>
              <p>Struktur Organisasi</p>
              <p>Visi & Misi</p>
            </Col>
            <Col sm="4">
              <h4>Informasi</h4>
              <p>Berita</p>
              <p>Kalender Event</p>
              <p>Indeks Pembangunan Kebudayaan</p>
            </Col>
            <Col sm="4">
              <h4>Kampung Budaya</h4>
              <p>Tentang Kami</p>
              <p>Struktur Organisasi</p>
              <p>Visi & Misi</p>
            </Col>
          </Row>
          {/* <hr className="barrier" /> */}
        </Container>
      </div>
    );
  }
}

export default Footer;
