import React from 'react';

import './Banner.css';

class Banner extends React.Component {
  render() {
    return (
      <div className="banner">

      </div>
    );
  }
}

export default Banner;
