import React from 'react';

import './Description.css';

class Description extends React.Component {
  render() {
    return (
      <div className="description-header">
        <h1 style={{textAlign: "center"}}>Borang Cagar Budaya Penyusunan Pokok Pikiran Kebudayaan Daerah 2019</h1>
        <p>Borang ini merupakan versi cetak dari borang Penyusunan Pokok Pikiran Kebudayaan Daerah (PPKD) 2019 Objek Cagar Budaya yang terdapat dalam sistem aplikasi PPKD.</p>
        <p>Catatan Pengisian (untuk diperhatikan)</p>
        <ol>
          <li><p>Satu kuesioner ini <strong>untuk seluruh objek Cagar Budaya dan yang diduga Cagar Budaya di tingkat Kabupaten/Kota</strong></p></li>
          <li><p>Untuk memudahkan pengisian, diharapkan untuk dapat membaca definisi operasional yang terlampir di <strong>Petunjuk Teknis Penyusunan PPKD.</strong></p></li>
        </ol>
      </div>
    );
  }
}

export default Description;
