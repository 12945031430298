import React from 'react'
import { UserContext } from './UserGlobalState'

const withUserState = ComposedComponent => props => {
	return (
		<UserContext.Consumer>
			{
				({ user, setUserData, setUserDataFromLocalStorage }) => (
					<ComposedComponent
						user={user}
						setUser={setUserData}
						setUserFromStorage={setUserDataFromLocalStorage}
						{...props} />
				)
			}
		</UserContext.Consumer>
	)
}

export default withUserState