import React from 'react';
import _ from 'lodash';
import moment from 'moment'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Pagination from "react-js-pagination";
import Button from 'react-bootstrap/Button';
import { AiFillPlusSquare } from 'react-icons/ai'

import Navigation from 'components/NavigationHome';
import Footer from 'components/Footer';
import ListCard from './components/ListCard';
import Choice from './components/Choice';
import ExploreModal from './components/Modal';
import ExploreModalDelete from './components/ModalDelete'

import './ExploreList.css';

import { ExploreApi } from '../../api'

var idLocale = require('moment/locale/id');
moment.locale('id', idLocale);
class ExploreList extends React.Component {
    state = {
        choose: '',
        items: [],
        categoryCultureId: 0,
        categoryCultureName: "",
        showModal: false,
        dataModal: {
            id: 0,
            categoryCulture: {},
            description: "",
            image: "",
            name: "",
        },
        modalType: "",
        page: 0,
        count: 0,
        showModalDelete: false,
    }

    componentWillMount() {
        const query = new URLSearchParams(this.props.location.search);
        let type = "wisata"
        let page = 1
        for (let param of query.entries()) {
            switch (param[0]) {
                case 'type':
                    type = param[1];
                    break;
                case 'page':
                    page = param[1];
                    break;
                default:
                    break;
            }
        }

        let { categoryCultureId, categoryCultureName } = this.state

        switch (type) {
            case "wisata":
                categoryCultureId = 1
                categoryCultureName = "Objek"
                break;
            case "budaya":
                categoryCultureId = 2
                categoryCultureName = "Budaya"
                break;
            case "heritage":
                categoryCultureId = 3
                categoryCultureName = "Heritage"
                break;
            case "museum":
                categoryCultureId = 4
                categoryCultureName = "Museum"
                break;
            case "kesenian":
                categoryCultureId = 5
                categoryCultureName = "Kesenian"
                break;
            default:
                break;
        }

        this.setState({ choose: type, categoryCultureId, categoryCultureName, page })
    }

    componentDidMount() {
        // fetch sesuai type
        const { categoryCultureId, page } = this.state
        this.fetchExplores(categoryCultureId, page)
    }

    fetch = () => {
        const { categoryCultureId, page } = this.state
        this.fetchExplores(categoryCultureId, page)
    }

    fetchExplores = (categoryCultureId, page) => {
        return new Promise((resolve, reject) => {
            ExploreApi.get({ limit: 5, page: page, categoryCultureId: categoryCultureId })
                .then(async ({ data }) => {
                    let items = data.data
                    let count = data.count
                    await this.setState({ items, count }, () => {
                        resolve()
                    })
                })
                .catch(err => {
                    alert(`Terjadi kesalahan saat mengambil data jelajah ${err}`)
                    reject()
                })

        })
    }

    changeChoose(choose) {
        window.open('/dashboard/explores?type=' + choose, '_self')
    }

    handleCloseModal = () => {
        this.setState({ showModal: false, dataModal: {} })
    }

    handleOpenModal = () => {
        this.setState({ showModal: true, modalType: "add" })
    }

    handleEditModal = (data) => {
        let { showModal, dataModal, modalType } = this.state
        showModal = true
        dataModal = data
        modalType = "edit"
        this.setState({ showModal, dataModal, modalType })
    }

    handleDelete = (id) => {
        const {categoryCultureName} = this.props
        ExploreApi.delete(id)
            .then(response => {
                alert("Culture berhasil dihapus!")
                setTimeout(() => {
                    this.setState({showModalDelete: false})
                    this.fetch()
                }, 300)
            })
            .catch(err => {
                alert(`Terjadi kesalahan pada hapus ${categoryCultureName} ${err}`)
            })
    }

    handleShowModalDelete = (dataModal) => {
        this.setState({ showModalDelete: true, dataModal })
    }

    handleCloseModalDelete = () => {
        this.setState({ showModalDelete: false, dataModal: {} })
    }

    handlePageChange(pageNumber) {
        const queryParams = [];
        const { choose } = this.state
        queryParams.push('type=' + choose);
        queryParams.push('page=' + pageNumber)
        const queryString = queryParams.join('&');
        window.open('/dashboard/explores?' + queryString, '_self')
    }

    render() {
        let i = 0
        let { choose, items, categoryCultureName, showModal, categoryCultureId, dataModal, modalType, count, page, showModalDelete } = this.state;
        return (
            <div className="explore-list" style={{minWidth: "1200px"}}>
                <Navigation />
                <Container className="content">
                    <Row>
                        <Col xs={6} sm={6}>
                            <h1 className="title">Jelajahi Makassar</h1>
                        </Col>
                    </Row>
                    <Choice changeChoose={this.changeChoose} choose={choose} />
                    <Row className="justify-content-end containerButton">
                        <Button className="buttonAdd" onClick={this.handleOpenModal}><AiFillPlusSquare size={20} color="white" style={{ marginRight: "5px" }} />Tambah {categoryCultureName}</Button>
                    </Row>
                    {
                        _.map(items, item => {
                            i++
                            return (
                                <div>
                                    <ListCard
                                        item={item}
                                        type="image"
                                        handleEdit={this.handleEditModal}
                                        handleDelete={this.handleDelete}
                                        handleShowModalDelete={this.handleShowModalDelete}
                                    />
                                    {i < 5 ? <hr style={{ marginBottom: "40px" }} /> : null}
                                </div>
                            )
                        })
                    }
                    {showModal && <ExploreModal
                        show={showModal}
                        categoryCultureId={categoryCultureId}
                        handleClose={this.handleCloseModal}
                        callback={this.fetch}
                        modalType={modalType}
                        dataModal={dataModal}
                        categoryCultureName={categoryCultureName}
                    />}
                    {count > 0 ?
                        <Row>
                            <Col>
                                <Pagination
                                    activePage={page}
                                    itemsCountPerPage={5}
                                    totalItemsCount={count}
                                    pageRangeDisplayed={5}
                                    onChange={this.handlePageChange.bind(this)}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                />
                            </Col>
                        </Row> : null
                    }
                    {showModalDelete && <ExploreModalDelete
                        show={showModalDelete}
                        handleDelete={this.handleDelete}
                        handleClose={this.handleCloseModalDelete}
                        dataModal={dataModal}
                    />}
                </Container>
                <Footer />
            </div>
        );
    }
}

export default ExploreList;
