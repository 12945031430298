import React from 'react'
import _ from 'lodash';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { BsImageFill } from 'react-icons/bs'
import { AiFillCloseCircle } from 'react-icons/ai';

import { UploadApi, NewsApi, CategoryNewsApi } from '../../../../api'

import './Modal.css'
import categoryNews from '../../../../api/categoryNews';

export default class NewsModal extends React.Component {
    state = {
        previewImage: null,
        previewVisible: false,
        fileName: "",
        modalData: {
            id: 0,
            file: {},
            name: "",
            description: "",
            image: "",
            publishedBy: "",
            categoryNewsId: 0,
            categoryNews: {},
        },
        validated: false,
        categoryNews: [],
        categoryChoosen: false,
    }

    componentDidMount() {
        let { modalData, previewImage, categoryChoosen, previewVisible } = this.state
        if (this.props.modalType === "edit") {
            const { dataModal } = this.props
            modalData.file = {}
            modalData.name = dataModal.name
            modalData.description = dataModal.description
            modalData.image = dataModal.image
            modalData.id = dataModal.id
            modalData.publishedBy = dataModal.publishedBy
            modalData.categoryNewsId = dataModal.categoryNewsId
            previewImage = dataModal.image
            categoryChoosen = true
            previewVisible = true
        }
        this.fetchCategoryNews()
        this.setState({ modalData, previewImage, categoryChoosen, previewVisible })
    }

    fetchCategoryNews = () => {
        return new Promise((resolve, reject) => {
            CategoryNewsApi.get({ limit: 0, page: 0 })
                .then(async ({ data }) => {
                    let categoryNews = data.data
                    await this.setState({ categoryNews }, () => {
                        resolve()
                    })
                })
                .catch(err => {
                    alert(`Terjadi kesalahan saat mengambil data kategory berita ${err}`)
                    reject()
                })

        })
    }

    setValidated(data) {
        let { validated } = this.state
        validated = data
        this.setState({ validated })
    }

    uploadProgress = (event) => {
        event.preventDefault()
        const file = URL.createObjectURL(event.target.files[0])
        let { modalData } = this.state
        modalData.file = event.target.files[0]
        this.setState({
            previewImage: file,
            previewVisible: true,
            fileName: event.target.files[0].name,
            modalData,
        })
    }

    removeUploadedFile = () => {
        let { modalData, previewImage, previewVisible, fileName } = this.state
        modalData.file = {}
        previewImage = null
        previewVisible = false
        fileName = ""
        this.setState({
            modalData,
            previewImage,
            previewVisible,
            fileName,
        })
    }

    getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    uploadFile = () => {
        let { modalData } = this.state
        if (modalData.hasOwnProperty('file')) {
            let file = modalData.file
            const payload = new FormData()
            if (modalData.file && modalData.file.size > 0) payload.append('uploadFile', file)
            return UploadApi.upload(payload)
        }
    }

    handleInputChange = (event, field) => {
        event.preventDefault()
        let { modalData } = this.state
        let data = event.target.value
        modalData[field] = data
        this.setState({ modalData })
        if (field === "categoryNewsId") {
            if (data == 0) {
                this.setState({categoryChoosen: false})
            }
            this.setState({categoryChoosen: true})
        }
    }

    submit = (event) => {
        const form = event.currentTarget;
        const {categoryChoosen} = this.state
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (form.checkValidity() === true && categoryChoosen) {
            event.preventDefault()
            let { modalData } = this.state
            if (modalData.file.size > 0) {
                const upload = this.uploadFile()
                upload.then(response => {
                    modalData.image = response.data.url
                    this.submitNews(modalData)
                }).catch(err => {
                    alert(err.message)
                })
            } else {
                this.submitNews(modalData)
            }
        }
        this.setValidated(true);
    }

    submitNews = (data) => {
        const { modalType } = this.props
        data.categoryNewsId = Number(data.categoryNewsId)
        if (modalType === "edit") {
            NewsApi.update(data.id, data)
                .then(response => {
                    alert("News berhasil diupdate!")
                    setTimeout(() => {
                        this.props.callback()
                        this.props.handleClose()
                    }, 300)
                })
                .catch(err => {
                    alert(`Terjadi kesalahan pada update berita ${err}`)
                })
        } else {
            NewsApi.create(data)
                .then(response => {
                    alert("News berhasil ditambah!")
                    setTimeout(() => {
                        this.props.callback()
                        this.props.handleClose()
                    }, 300)
                })
                .catch(err => {
                    alert(`Terjadi kesalahan pada tambah berita ${err}`)
                })
        }

    }

    render() {
        const { show, handleClose, modalType } = this.props
        let { previewVisible, previewImage, fileName, modalData, validated, categoryNews, categoryChoosen } = this.state
        if (fileName === "") {
            fileName = "Masukkan Gambar"
        }
        return (
            <div className="news-modal">
                <Modal show={show} onHide={handleClose} size="xl" centered>
                    <Form noValidate validated={validated} onSubmit={this.submit}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {`${modalType === "edit" ? "Ubah Berita" : "Tambah Berita"}`}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col xs={7} lg={7} className="container-left">
                                    <Form.File id="custom-file" label={fileName} custom onChange={this.uploadProgress} />
                                    {!previewVisible && <div className="container-image">
                                        <BsImageFill size={70} color="#f29e20" style={{ margin: "auto auto" }} />
                                    </div>}
                                    {previewVisible && <div className="container-previewImage">
                                        <AiFillCloseCircle color="#f29e20" onClick={this.removeUploadedFile} />
                                        <img src={previewImage} className="image-preview" />
                                    </div>}
                                </Col>
                                <Col xs={5} lg={5}>
                                    <Form.Group>
                                        <Form.Label>Judul</Form.Label>
                                        <Form.Control required type="text" placeholder="Judul" value={modalData.name} onChange={e => this.handleInputChange(e, 'name')} />
                                        <Form.Control.Feedback type="invalid">judul berita harus diisi.</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Kategori</Form.Label>
                                        <Form.Control value={modalData.categoryNewsId} isValid={categoryChoosen} isInvalid={!categoryChoosen} as="select" onChange={e => this.handleInputChange(e, 'categoryNewsId')}>
                                            <option value={0}>Pilih...</option>
                                            {_.map(categoryNews, categoryNew => {
                                                return <option value={categoryNew.id}>{categoryNew.name}</option>
                                            })}
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Penerbit</Form.Label>
                                        <Form.Control required type="text" placeholder="Penerbit" value={modalData.publishedBy} onChange={e => this.handleInputChange(e, 'publishedBy')} />
                                        <Form.Control.Feedback type="invalid">penerbit harus diisi.</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Deskripsi</Form.Label>
                                        <Form.Control as="textarea" rows="6" placeholder="Deskripsi" onChange={e => this.handleInputChange(e, 'description')} value={modalData.description} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" type="submit">{`${modalType === "edit" ? "Ubah" : "Tambah"}`}</Button>
                            <Button variant="secondary" onClick={handleClose}>Batal</Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </div>
        )
    }
}