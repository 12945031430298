/* eslint-disable */
/* eslint-enable */

import axios from 'axios'
import { setCookie } from '../utils/cookies'
import User from './user'
import Page from './page'
import Upload from './upload'
import News from './news'
import Explore from './explore'
import CategoryNews from './categoryNews'
import Inspiration from './inspiration'


import {
	API_URL,
	COOKIES,
	SITE_COOKIES,
	MENU
} from '../config'

export const configApi = ({ contentType, type } = {}) => {
	const params = {
		headers: {
			'Content-Type': contentType || 'application/json',
			'Authorization': `Bearer ${type === 'dashboard' ? COOKIES.get('sessionId') : 'budaya-makassar'}`,
		}
	}

	return params
}

// Destroy access
export const BACK_TO_LOGIN = (isExpired = false) => {
	Object.keys(SITE_COOKIES).forEach(async key => {
		await setCookie('sessionId', null, -1)
		await setCookie(SITE_COOKIES[key], null, -1)
	})
	localStorage.removeItem('userBudaya')
	const target = isExpired ? MENU.LOGIN : MENU.EXPLORE
	window.location.reload(true)
	window.location = target
}

const params = { url: API_URL, config: configApi }
export const UserApi = User(params)
export const PageApi = Page(params)
export const UploadApi = Upload(params)
export const NewsApi = News(params)
export const ExploreApi = Explore(params)
export const CategoryNewsApi = CategoryNews(params)
export const InspirationApi = Inspiration(params)
