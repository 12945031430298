import React from 'react';
import _ from 'lodash';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import { AiFillMinusSquare, AiFillPlusSquare } from 'react-icons/ai';


class Form5 extends React.Component {
    render() {
        const { modalData, touched, handleInputChange, handleNext, handlePrev, handleInputArrayChange, handleAddArrayChange, handleDeleteArrayChange, handleSubmit, typeModal } = this.props
        return (
            <div>
                <h1>Blok V : Identifikasi Bahasa yang Telah Punah</h1>
                <Form.Group>
                    <Form.Label className="label">Tuliskan dalam tabel di bawah ini alasan dan catatan khusus untuk bahasa tersebut sehingga sudah tidak dituturkan lagi</Form.Label>
                    <Form.Group>
                        <Form.Label className="label" style={{ marginTop: "10px" }}>Alasan Tidak Dituturkan</Form.Label>
                        <Form.Control disabled={typeModal === 'edit'} required as="textarea" placeholder="Alasan Tidak Dituturkan" row={5} value={modalData.alasanTidakDituturkan} isInvalid={touched.alasanTidakDituturkan && !modalData.alasanTidakDituturkan} onChange={e => handleInputChange(e, 'alasanTidakDituturkan')} />
                        <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className="label" style={{ marginTop: "10px" }}>Catatan Khusus</Form.Label>
                        <Form.Control disabled={typeModal === 'edit'} required as="textarea" placeholder="Catatan Khusus" row={5} value={modalData.catatanKhusus} isInvalid={touched.catatanKhusus && !modalData.catatanKhusus} onChange={e => handleInputChange(e, 'catatanKhusus')} />
                        <Form.Control.Feedback type="invalid">harus diisi.</Form.Control.Feedback>
                    </Form.Group>
                </Form.Group>
                <Button onClick={handlePrev} style={{ marginRight: "10px", marginTop: "10px" }}>Prev</Button>
                <Button onClick={handleSubmit} style={{ marginRight: "10px", marginTop: "10px" }}>{`${typeModal === "edit" ? `List Borang` : `Submit`}`}</Button>
            </div >
        )
    }
}

export default Form5;
